import { NavLink , useHistory } from "react-router-dom";

export default function PaymentSuccess() {
    let history = useHistory()
    return <>


        <div className="fix">
            <div className="container py-0 text-center py-4">
                <h6 className="tittle">Siparişiniz Başarıyla oluşturuldu</h6>
                <div className="text mt-2">Kargonuzun durumunu <span style={{cursor: 'pointer', color: '#2A9CF5'}} onClick={() => history.push('/account')} >Siparişlerim</span> ekranından takip edebilirsiniz.</div>
                <NavLink to="/shop" class="">
                    <button className="btn btn-primary btn-rounded btn-icon-right mt-3 mb-10">Alışverişe Devam Et</button>
                </NavLink>
            </div>


        </div>
    </>
}
