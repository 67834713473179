import Counter from "../../../../../Ekmekk/component/counter";
import { ProgressBar } from "react-bootstrap";
import { useEffect } from "react";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import BasketManager from "../../../../../Ekmekk/controller/BasketManager";
import { useParams } from "react-router-dom";
import { useState } from "react";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import Loading from "../../../../../Ekmekk/component/spinner";
import React from 'react'
import { useHistory } from "react-router";
import { useRecoilValue, useRecoilState } from "recoil";
import { renderHeader } from "../../../../../Ekmekk/global-data/atom";
import { allParametersList } from "../../../../../Ekmekk/global-data/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const ProductDetail = () => {

  const parameterList = useRecoilValue(allParametersList);
  const [render, setRender] = useRecoilState(renderHeader)


  let history = useHistory()

  var productID = useParams("id");
  const [product, setProduct] = useState("");
  const [optionGroup, setOptionGroup] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(-1);
  const [selectedStock, setSelectedStock] = useState(-1);
  const [listProduct, setListProduct] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [dateList, setDateList] = useState([])






  const [themeColor, setThemeColor] = useState("")
  function SetCount(direction)
  {
    if( product.count+direction >0)
    {
      product.count +=direction;
      setProduct(JSON.parse(JSON.stringify(product)))
    }
   
  }
  useEffect(() => {

    setThemeColor(MyHelper().GetThemeColor(parameterList));
    console.log(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])

  useEffect(() => {

    DataManager().Product.GetProductDetail(productID.id).then((r) => {
      if (r.priceList != undefined) {
        if (r.priceList.length == 1) {
          setSelectedPrice(parseFloat(r.priceList[0].ek_sell_price));
          setSelectedStock(parseInt(r.priceList[0].ek_stock))
        }
        else if (r.priceList.length > 1) {
          setSelectedPrice(parseFloat(r.priceList[r.priceList.length - 1].ek_sell_price));
          setSelectedStock(parseInt(r.priceList[r.priceList.length - 1].ek_stock))


          // EĞER ALT GRUPLUYSA İLK ELEMANLAR SEÇİLİ GETİRTME
          // console.log(r.items[0].subItem.split(",")[0].split("-")[1]);

          // r.items[0].selected = r.items[0].subItem.split(",")[0].split("-")[1]
          // selectedIteem(r.items[0], r.items[0].subItem.split(",")[0].split("-")[1], 0, r.items[0].subItem.split(",")[0].split("-")[0], r)

          // if (r.items[1].ek_tittle != null) {
          //     r.items[1].selected = r.items[1].subItem.split(",")[0].split("-")[1]
          //     selectedIteem(r.items[1], r.items[1].subItem.split(",")[0].split("-")[1], 1, r.items[1].subItem.split(",")[1].split("-")[0], r)
          // }

          // console.log(r.items[0] + " " + r.items[0].subItem.split(",")[0].split("-")[1] + " " + 0 + " " + r.items[0].subItem.split(",")[0].split("-")[0])
          // console.log(r.items[1] + " " + r.items[1].subItem.split(",")[0].split("-")[1] + " " + 1 + " " + r.items[1].subItem.split(",")[0].split("-")[0])

        }

        if (r.items.length > 1 && r.items[0].ek_tittle != null) {

          // EĞER ALT GRUPLUYSA İLK ELEMANLAR SEÇİLİ GETİRTME
          console.log(r.items[0].subItem.split(",")[0].split("-")[1]);

          r.items[0].selected = r.items[0].subItem.split(",")[0].split("-")[1]
          selectedIteem(r.items[0], r.items[0].subItem.split(",")[0].split("-")[1], 0, r.items[0].subItem.split(",")[0].split("-")[0], r)

          if (r.items[1].ek_tittle != null) {
            r.items[1].selected = r.items[1].subItem.split(",")[0].split("-")[1]
            selectedIteem(r.items[1], r.items[1].subItem.split(",")[0].split("-")[1], 1, r.items[1].subItem.split(",")[1].split("-")[0], r)
          }
        }


      }

      r["count"] = 1;
      setProduct(r);

    });

    DataManager().Product.GetAllProduct().then((r) => {
      setListProduct([...r])
      setTimeout(function () { setLoading(false) }, 500);
    })

  }, [productID])

  function selectedIteem(item, subItem, index, subItemTittle, r) {

    optionGroup[index] = { groupTittle: item.ek_tittle, itemTittle: subItemTittle, groupID: item.ek_option_group_id, itemID: subItem };

    if (r != undefined) {
      var bash = JSON.stringify(r);
    }
    else {
      var bash = JSON.stringify(product);
    }
    bash = JSON.parse(bash);
    bash.items.map((f, index) => {
      // console.log(f);
      // console.log(item);
      if (f.ek_tittle == item.ek_tittle) {
        // console.log(f);
        // console.log(item);
        bash.items[index]["selected"] = subItem;
      }

    })

    setSelectedPrice(-1);
    setSelectedStock(-1);
    bash.priceList.map(i => {
      if ((i.ek_first_option_group == bash.items[0].selected && i.ek_second_option_group == bash.items[1].selected) || (i.ek_first_option_group == bash.items[1].selected && i.ek_second_option_group == bash.items[0].selected)) {

        setSelectedPrice(parseFloat(i.ek_sell_price));
        setSelectedStock(parseFloat(i.ek_stock));
      }
    })

    // EĞER TEK ALT GRUPLU BİR ÜRÜNSE
    bash.priceList.map(i => {
      if (bash.items[1].ek_tittle == null) {
        if ((i.ek_first_option_group == bash.items[0].selected) || (i.ek_first_option_group == bash.items[1].selected)) {

          setSelectedPrice(parseFloat(i.ek_sell_price));
          setSelectedStock(parseFloat(i.ek_stock));
        }
      }

    })





    setProduct(bash)
    console.log(bash)
  }


  function AddBasket() {

    product["group"] = optionGroup;
    product["price"] = selectedPrice

    if (product.count > 0 || product.count == undefined) {
      BasketManager().AddProduct(product)
      global.basketRefresh();
      if (product.count == undefined) {
        MyHelper().AlertSuccess("1 adet ürün sepete başarıyla eklendi")
        setRender(BasketManager().GetAllProductsNormal())
      }
      else {
        MyHelper().AlertSuccess(product.count + " adet ürün sepete başarıyla eklendi")
        setRender(BasketManager().GetAllProductsNormal())
      }

    }
    else {
      MyHelper().AlertFail("Lütfen ürün sayısı seçiniz")
    }

  }
  return (
    <>
      <div>
        <div className="page-wrapper">
          {/* Start of Main */}
          <main className="main mb-10 pb-1">
           
            <div style={{ marginTop: '50px' }} className="page-content">
              <div className="container">
                <div className="row gutter-lg d-flex justify-content-center">
                  <div className="main-content " >
                    <div className="product product-single row">
                      <div className="col-md-6 mb-6">
                        <div className="product-gallery product-gallery-sticky">

                         
                        <OwlCarousel
                            className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-no"
                          
                            nav={true}
                            items={1}
                            
                            height={"10%"}
                            dots={true}
                          >

                            {
                              product == undefined ? "" : product.images != null && product.images.split(",").map((item, index) => {
                                return (
                                  <figure className="product-image">
                                    <img  src={item.split("$")[0]} data-zoom-image={item.split("$")[0]} alt="Ürün" style={{ objectFit:"contain", maxWidth: 800, maxHeight: 500 ,minHeight:500}}  />
                                  </figure>
                                )
                              })
                            }


                          </OwlCarousel>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4 mb-md-6">
                        <div className="product-details" data-sticky-options="{'minWidth': 767}">
                          <h2 className="product-title">{product.ek_product_name}</h2>
                          <div className="product-bm-wrapper">

                          </div>
                          <div class="product-price"><ins class="new-price">{(selectedPrice == -1 ? "Stok Yok" : (selectedPrice.toFixed(2) + " ₺"))}</ins></div>
                          <hr className="product-divider" />

                          <div className="product-short-desc">
                            <ul className="list-type-check list-style-none">
                              <div className="text my-4">
                                <div dangerouslySetInnerHTML={{ __html: product.ek_product_description }} />
                              </div>
                            </ul>
                          </div>
                         
                          {

                            product != "" && product.items.map((item, index) => {
                              if(item.ek_tittle != null)
                              {
                                return <>
                                <div className="product-form product-variation-form product-size-swatch">
                                  <label className="mb-1">{item.ek_tittle}:</label>
                                  <div className="flex-wrap d-flex align-items-center product-variations">
                                    
                                  {
                                    item.subItem == undefined ? "" : item.subItem.split(",").map(item2 => {
                                      return <a  className={item.selected == item2.split("-")[1] ? "size active" : "size"} onClick={() => { selectedIteem(item, item2.split("-")[1], index, item2.split("-")[0]); }}>{item2.split("-")[0]}</a>
                                    })
                                  }
                                  </div>
                                  <a href="#" className="product-variation-clean" style={{ display: 'none' }}>Clean All</a>
                                </div>


                              </>
                              }
                              
                            })
                          }


                          <hr className="product-divider" />
                         
                          <div className="sticky-content-wrapper">
                            <div className="fix-bottom product-sticky-content sticky-content">
                              <div className="product-form container" >

                                <div className="product-qty-form">
                                  <Counter data={SetCount} selectedItem={product.count} limit={selectedStock} color={themeColor}></Counter>
                                </div>
                                <button className="btn btn-light-primary btn-cart" onClick={AddBasket}>
                                  <i className="w-icon-cart" />
                                  <span>Sepete Ekle</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="product-variation-price">
                            <span />
                          </div>

                          <div className="fix-bottom product-sticky-content sticky-content">
                            <div className="product-form container">






                              <div className="row">




                                <div className="product-qty-form col-12 mt-5">
                                  <div className="input-group">
                                    {
                                      selectedStock && selectedStock != -1 ? <div className="d-flex align-items-center justify-content-start " style={{ color: "" + themeColor + "" }}>



                                      </div> : ""
                                    }
                                  </div>
                                </div>

                              </div>


                            </div>
                          </div>


                        </div>
                      </div>
                    </div>




                  </div>


                </div>
              </div>
            </div>
          </main>
        </div>

        {/* Start of Scroll Top */}

        <div className="product product-single product-popup">
          <div className="row gutter-lg">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="product-gallery product-gallery-sticky mb-0">
                <div className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-no">
                  <figure className="product-image">
                    <img src="assets/images/products/popup/1-440x494.jpg" data-zoom-image="assets/images/products/popup/1-800x900.jpg" alt="Water Boil Black Utensil" width={800} height={900} />
                  </figure>
                  <figure className="product-image">
                    <img src="assets/images/products/popup/2-440x494.jpg" data-zoom-image="assets/images/products/popup/2-800x900.jpg" alt="Water Boil Black Utensil" width={800} height={900} />
                  </figure>
                  <figure className="product-image">
                    <img src="assets/images/products/popup/3-440x494.jpg" data-zoom-image="assets/images/products/popup/3-800x900.jpg" alt="Water Boil Black Utensil" width={800} height={900} />
                  </figure>
                  <figure className="product-image">
                    <img src="assets/images/products/popup/4-440x494.jpg" data-zoom-image="assets/images/products/popup/4-800x900.jpg" alt="Water Boil Black Utensil" width={800} height={900} />
                  </figure>
                </div>
                <div className="product-thumbs-wrap">
                  <div className="product-thumbs">
                    <div className="product-thumb active">
                      <img src="assets/images/products/popup/1-103x116.jpg" alt="Product Thumb" width={103} height={116} />
                    </div>
                    <div className="product-thumb">
                      <img src="assets/images/products/popup/2-103x116.jpg" alt="Product Thumb" width={103} height={116} />
                    </div>
                    <div className="product-thumb">
                      <img src="assets/images/products/popup/3-103x116.jpg" alt="Product Thumb" width={103} height={116} />
                    </div>
                    <div className="product-thumb">
                      <img src="assets/images/products/popup/4-103x116.jpg" alt="Product Thumb" width={103} height={116} />
                    </div>
                  </div>
                  <button className="thumb-up disabled"><i className="w-icon-angle-left" /></button>
                  <button className="thumb-down disabled"><i className="w-icon-angle-right" /></button>
                </div>
              </div>
            </div>
            <div className="col-md-6 overflow-hidden p-relative">
              <div className="product-details scrollable pl-0">
                <h2 className="product-title">Electronics Black Wrist Watch</h2>
                <div className="product-bm-wrapper">
                  <figure className="brand">
                    <img src="assets/images/products/brand/brand-1.jpg" alt="Brand" width={102} height={48} />
                  </figure>
                  <div className="product-meta">
                    <div className="product-categories">
                      Category:
                      <span className="product-category"><a href="#">Electronics</a></span>
                    </div>
                    <div className="product-sku">
                      SKU: <span>MS46891340</span>
                    </div>
                  </div>
                </div>
                <hr className="product-divider" />
                <div className="product-price">$40.00</div>
                <div className="ratings-container">
                  <div className="ratings-full">
                    <span className="ratings" style={{ width: '80%' }} />
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  <a href="#" className="rating-reviews">(3 Reviews)</a>
                </div>
                <div className="product-short-desc">
                  <ul className="list-type-check list-style-none">
                    <li>Ultrices eros in cursus turpis massa cursus mattis.</li>
                    <li>Volutpat ac tincidunt vitae semper quis lectus.</li>
                    <li>Aliquam id diam maecenas ultricies mi eget mauris.</li>
                  </ul>
                </div>
                <hr className="product-divider" />
                <div className="product-form product-variation-form product-color-swatch">
                  <label>Color:</label>
                  <div className="d-flex align-items-center product-variations">
                    <a href="#" className="color" style={{ backgroundColor: '#ffcc01' }} />
                    <a href="#" className="color" style={{ backgroundColor: '#ca6d00' }} />
                    <a href="#" className="color" style={{ backgroundColor: '#1c93cb' }} />
                    <a href="#" className="color" style={{ backgroundColor: '#ccc' }} />
                    <a href="#" className="color" style={{ backgroundColor: '#333' }} />
                  </div>
                </div>
                <div className="product-form product-variation-form product-size-swatch">
                  <label className="mb-1">Size:</label>
                  <div className="flex-wrap d-flex align-items-center product-variations">
                    <a href="#" className="size">Small</a>
                    <a href="#" className="size">Medium</a>
                    <a href="#" className="size">Large</a>
                    <a href="#" className="size">Extra Large</a>
                  </div>
                  <a href="#" className="product-variation-clean">Clean All</a>
                </div>
                <div className="product-variation-price">
                  <span />
                </div>
                <div className="product-form">
                  <div className="product-qty-form">
                    <div className="input-group">
                      <input className="quantity form-control" type="number" min={1} max={10000000} />
                      <button className="quantity-plus w-icon-plus" />
                      <button className="quantity-minus w-icon-minus" />
                    </div>
                  </div>
                  <button className="btn btn-primary btn-cart">
                    <i className="w-icon-cart" />
                    <span>Add to Cart</span>
                  </button>
                </div>
                <div className="social-links-wrapper">
                  <div className="social-links">
                    <div className="social-icons social-no-color border-thin">
                      <a href="#" className="social-icon social-facebook w-icon-facebook" />
                      <a href="#" className="social-icon social-twitter w-icon-twitter" />
                      <a href="#" className="social-icon social-pinterest fab fa-pinterest-p" />
                      <a href="#" className="social-icon social-whatsapp fab fa-whatsapp" />
                      <a href="#" className="social-icon social-youtube fab fa-linkedin-in" />
                    </div>
                  </div>
                  <span className="divider d-xs-show" />
                  <div className="product-link-wrapper d-flex">
                    <a href="#" className="btn-product-icon btn-wishlist w-icon-heart"><span /></a>
                    <a href="#" className="btn-product-icon btn-compare btn-icon-left w-icon-compare"><span /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ProductDetail
