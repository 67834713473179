import { creditCardList } from '../../util/cartList';
import { NavLink, Redirect, useHistory } from "react-router-dom"
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faMehRollingEyes, faMoneyBill, faMoneyBillWaveAlt, faMoneyCheck, faPlusSquare, faRuler } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import BasketManager from "../../../../../Ekmekk/controller/BasketManager";
import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../../Ekmekk/global-data/selectors";
import Iyzico from "../../../../../Ekmekk/payment/iyzico/Iyzico";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { useRef } from "react";
import Validator, { ValidationController } from '../../../../../Ekmekk/helper/Validator'
import Card from 'react-credit-card-payment'
import WrappedFrame from '../../Components/WrappedFrame';
import swal from 'sweetalert2/dist/sweetalert2.all.min.js'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
const utf8 = require('utf8');
var base64 = require('base-64');

export const CheckOut = () => {
    var history = useHistory();
    const parameterList = useRecoilValue(allParametersList);
    const [baskeetItems, setBasketItems] = useState();
    const [iframeData, setIframeData] = useState();
    const [addressList, setAddressList] = useState([]);

    const [toplamTutar, setToplamTutar] = useState(0);
    const [isUserOnline, setİsUserOnline] = useState(false);
    const [show, setShow] = useState(false);



    const [inputCreditCardNumber, setInputCreditCardNumber] = useState("123");
    const [inputCreditCardName, setInputCreditCardName] = useState("");
    const [inputCreditCardLastDate, setInputCreditLastDate] = useState("");
    const [inputCreditCardCvc, setInputCreditCardCvc] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankType, setBankType] = useState("");


    const [themeColor, setThemeColor] = useState("")
    const [pageSecurity, setPageSecurity] = useState(false)
    useEffect(() => {
        setPageSecurity(localStorage.getItem('kahvenin_delisi_user_id') == "undefined" ? false : true)
        setThemeColor(MyHelper().GetThemeColor(parameterList));
        console.log(MyHelper().GetThemeColor(parameterList));
    }, [parameterList])


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    function bindEvents(event) {
        var data = JSON.parse(event.data);

        if (data.status == "failure") {

            Swal.close();
            if (data.type == "before") {
                Swal.fire({
                    title: 'Ops?',
                    text: data.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Tamam'
                }).then((result) => {


                })
            }
            else {
                Swal.fire({
                    title: 'Ops?',
                    text: "Bir problem oluştu lütfen tekrar deneyin!",
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Tamam'
                }).then((result) => {

                    history.push("/shoppingcard")
                })
            }

        }
        else if (data.status == "success") {

            Swal.close();
            if (MyHelper().IsLogin()) {

                var userID = MyHelper().GetUserID();
                var addressItem = addressList.filter((item) => { if (item.selected) { return item.selected } });


                if (addressItem.length == 0) {
                    MyHelper().AlertFail("Lütfen adres seçiniz")
                }
                else {
                    var addressID = addressItem[0].ek_address_id;

                    var basketItems = BasketManager().GetAllProductsNormal();

                    var orderItems = [];

                    console.log(basketItems);
                    basketItems.map(item => {
                        if (item.days > 0) {
                            orderItems.push({
                                productID: item.ek_id,
                                startDate: item.entryDate,
                                endDate: item.exitDate,
                                isServices: 1
                            })
                        } else {
                            orderItems.push({
                                productID: item.ek_id,
                                optionOne: item.group[0] == undefined ? -1 : item.group[0].itemID,
                                optionTwo: item.group[1] == undefined ? -1 : item.group[1].itemID,
                                sellPrice: 1,
                                group: item.group,
                                count: item.count
                            })
                        }

                    })

                    DataManager().Order.CreateOrder(orderItems, addressID, userID).then((r) => {
                        console.log(r);
                        console.log(orderItems);

                        BasketManager().Clear();

                        Swal.fire({
                            title: 'Siparişiniz oluşturuldu?',
                            text: "Siparişiniz oluşturuldu. Siparişinizi takip etmek için Hesabım>Siparişlerim adımını takip edebilirsiniz!",
                            icon: 'success',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                            history.push("/shoppingcard")
                        })
                    });
                }

            }
            else {
                MyHelper().AlertFillAll("Lütfen Giriş Yapınız")

            }

        }


        if (!isEnd) {
            //isEnd = true;
            //console.log(data);


            // console.log("http://www.kahvenindelisi.com/services/iyzico/samples/paymentEnd.php?paymentID=" + data[1].paymentId + "&conversationId=" + data[3].conversationId);

            // setIframeData("http://www.kahvenindelisi.com/services/iyzico/samples/paymentEnd.php?paymentID=" + data[1].paymentId + "&conversationId=" + data[3].conversationId)
        }




    }

    useEffect(() => {
        window.addEventListener("message", bindEvents, false);
        setBasketItems(BasketManager().GetAllProductsNormal());
        console.log(BasketManager().GetAllProductsNormal())


        if (MyHelper().IsLogin()) {
            setİsUserOnline(true);


            DataManager().Users.GetAllAddress().then(r => {

                // alert(JSON.stringify(r))
                if (r.length != 0)
                    r[0].selected = true;
                else
                    MyHelper().AlertFillAll("Lütfen Adres Giriniz")

                setAddressList(r);

            })
        }
        else {
            setİsUserOnline(false);
            // MyHelper().AlertFillAll("Lütfen Giriş Yapınız")
            // history.push("/login")

        }


        GetTotalPrice();


    }, [])

    function GetTotalPrice() {

        var dizi = BasketManager().GetAllProductsNormal()
        var tutar = 0;
        for (let i = 0; i < dizi.length; i++) {
            if (dizi[i].days != undefined) {
                dizi[i].count = dizi[i].days
            }
            tutar = tutar + (dizi[i].count * dizi[i].price);
        }
        setToplamTutar(tutar.toFixed(2));

    }
    var isEnd = false;

    function CreateOrder() {

        var isTest = false;

        if(isTest)
        {
            var userID = MyHelper().GetUserID();
            var addressItem = addressList.filter((item) => { if (item.selected) { return item.selected } });


            if (addressItem.length == 0) {
                MyHelper().AlertFail("Lütfen adres seçiniz")
            }
            else {
                var addressID = addressItem[0].ek_address_id;

                var basketItems = BasketManager().GetAllProductsNormal();

                var orderItems = [];

                console.log(basketItems);
                basketItems.map(item => {
                    if (item.days > 0) {
                        orderItems.push({
                            productID: item.ek_id,
                            startDate: item.entryDate,
                            endDate: item.exitDate,
                            isServices: 1
                        })
                    } else {
                        orderItems.push({
                            productID: item.ek_id,
                            optionOne: item.group[0] == undefined ? -1 : item.group[0].itemID,
                            optionTwo: item.group[1] == undefined ? -1 : item.group[1].itemID,
                            sellPrice: 1,
                            group: item.group,
                            count: item.count
                        })
                    }

                })

                DataManager().Order.CreateOrder(orderItems, addressID, userID).then((r) => {
                    console.log(r);
                    console.log(orderItems);

                    BasketManager().Clear();

                    Swal.fire({
                        title: 'Siparişiniz oluşturuldu?',
                        text: "Siparişiniz oluşturuldu. Siparişinizi takip etmek için Hesabım>Siparişlerim adımını takip edebilirsiniz!",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Tamam'
                    }).then((result) => {
                        history.push("/shop")
                    })
                });
            }

        }
        else
        {
            var validator = new ValidationController();
            if (validator.Control() == 0) {
    
    
                //handleShow(true);
    
                var creditCardNumber = document.getElementById("input_credit_card_number").value;
                var creditCardName = document.getElementById("input_credit_card_name").value;
                var creditCardLastDate = document.getElementById("input_credit_card_expiry").value;
                var creditCardCvc = document.getElementById("input_credit_card_cvc").value;
                var addressItem = addressList.filter((item) => { if (item.selected) { return item.selected } });
                var basketItems = BasketManager().GetAllProductsNormal();
                var totalPrice = 0;
                basketItems.map(item => {
                    totalPrice += item.count * item.price;
                })
                var jsonBlock = {
                    "abstract":
                    {
                        "ip": "",
                        "price": totalPrice,
                    },
                    "cardData":
                    {
                        "namesurname": creditCardName,
                        "creditCardNumber": creditCardNumber,
                        "lastMonth": creditCardLastDate.split('/')[0],
                        "lastYear": "20" + creditCardLastDate.split('/')[1],
                        "cvc": creditCardCvc
                    },
    
                    "shippingAddress":
                    {
                        "contactName": addressItem[0]["ek_owner_name"],
                        "city": addressItem[0]["ek_city"],
                        "country": addressItem[0]["ek_country"],
                        "address": addressItem[0]["ek_open_address"]
                    },
                    "billingAddress":
                    {
                        "contactName": addressItem[0]["ek_owner_name"],
                        "city": addressItem[0]["ek_city"],
                        "country": addressItem[0]["ek_country"],
                        "address": addressItem[0]["ek_open_address"]
                    },
                    "buyer": {
                        "id": "1",
                        "name": "Furkan Bahadır ",
                        "surname": "Özdemir ",
    
                        "mail": "furkan@ekmekk.app",
                        "ip": "85.34.78.112",
                        "address": addressItem[0]["ek_open_address"],
                        "city": addressItem[0]["ek_city"],
                        "country": addressItem[0]["ek_country"],
                        "tckn": "74300864791"
                    },
                    "products":
                        [
                            {
                                "id": "1",
                                "name": "1",
                                "category1": "1",
                                "itemType": "1",
                                "price": "1"
    
                            }
                        ]
                };
                ;
    
                jsonBlock.products = [];
    
                basketItems.map(item => {
    
                    for (var i = 0; i < item.count; i++) {
                        jsonBlock.products.push({
                            id: item.ek_id,
                            name: item.ek_product_name,
                            category1: item.ek_category_id,
                            price: item.price,
                        })
    
                    }
    
                })
    
                var text = JSON.stringify(jsonBlock);
                var bytes = utf8.encode(text);
                var encoded = base64.encode(bytes);
    
    
    
                Swal.fire({
                    title: 'SMS Doğrulama',
                    html: '    <iframe style="width:100%;height:400px" src="' + (MyHelper().URLwithoutS() + "/services/iyzico/samples/initialize_checkout_form.php?dataBlock=" + encoded) + '" title="W3Schools Free Online Web Tutorials"></iframe>'
                    , showCloseButton: false,
                    showCancelButton: false,
                    showConfirmButton: false,
                    focusConfirm: false,
                    width: 600,
                    heightAuto: true,
                    confirmButtonText:"",
                    confirmButtonAriaLabel: 'Thumbs up, great!',
                    cancelButtonText:
                        '<i class="fa fa-thumbs-down"></i>',
                    cancelButtonAriaLabel: 'Thumbs down'
                })
                setIframeData(MyHelper().URLwithoutS() + "/services/iyzico/samples/initialize_checkout_form.php?dataBlock=" + encoded)
                // setShow(true);
    
    
    
    
    
            }
        }
     

    }

    function ChangeCreditCardNumber(newValue) {
        setInputCreditCardNumber(newValue);

        if (newValue.length > 5) {


            creditCardList.map((item) => {

                if (item.PrefixNo.toString() == newValue.substring(0, 6).toString()) {
                    if (newValue.substring(0, 1) == 4) {
                        setBankType("visa");
                    }
                    else if (newValue.substring(0, 1) == 5) {
                        setBankType("mastercard");
                    }
                    setBankName(item.MemberName);

                }
            })
        }

    }


    return (
        <>
            {
                MyHelper().IsLogin() ? "" : <Redirect to="/auth" />
            }
            <main className="main checkout mt-4">
                <div className="page-content">
                    <div className="container">

                        <form className="form checkout-form" action="#" method="post">


                            <div className="row mb-9">


                                <div className="col-lg-7 pr-lg-4 mb-4">

                                    <div className="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">

                                        <div className="d-flex justify-content-between w-100">
                                            <div>
                                                <div className="icon-box icon-box-side icon-box-light">
                                                    <span className="icon-box-icon icon-map-marker"><i className="w-icon-map-marker" /></span>
                                                    <div className="icon-box-content">
                                                        <h4 className="icon-box-title mb-0 ls-normal">Teslimat Bilgileri</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div><button onClick={() => { history.push("/account/address/insert") }} id="address_insert" className="btn btn-light-primary btn-cart">Adres Ekle</button></div>
                                        </div>

                                    </div>


                                    <div className="row gutter-sm">
                                        {isUserOnline == false ? "asf" : AddressAndPayment()}

                                    </div>



                                </div>
                                <div className="col-lg-5 mb-4 sticky-sidebar-wrapper">
                                    <div className="pin-wrapper" ><div className="order-summary-wrapper sticky-sidebar" style={{ width: '505px' }}>
                                        <h3 className="title text-uppercase ls-10">Ödeme Özeti</h3>
                                        <div className="order-summary">
                                            <Validator>
                                                <table className="order-table">
                                                    <tbody  >
                                                        <tr className="bb-no">
                                                            <td style={{ fontSize: '1.5rem' }} className="product-name"> <i className="w-icon-money mr-1" />Sipariş Tutarı </td>
                                                            <td style={{ fontSize: '1.5rem' }} className="product-total">{toplamTutar && toplamTutar}</td>
                                                        </tr>
                                                        <tr className="bb-no">
                                                            <td style={{ fontSize: '1.5rem' }} className="product-name"><i className="w-icon-cart2 mr-1" />Tahmini Teslim Tarihi
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }} className="product-total">{MyHelper().GetCargoDate(parameterList)} İş Günü</td>
                                                        </tr>
                                                        <tr className="bb-no">
                                                            <td style={{ fontSize: '1.5rem' }} className="product-name"><i className="w-icon-truck mr-1" />Kargo Ulaşım Bedeli
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }} className="product-total">{toplamTutar >= MyHelper().GetCargoLimit(parameterList) ? "0.00" : MyHelper().GetCargoPrice(parameterList).toFixed(2)} ₺</td>
                                                        </tr>
                                                        <tr className="bb-no">
                                                            <td style={{ fontSize: '1.5rem' }} className="product-name">
                                                                <hr className="divider mb-2 mt-6" />
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }} className="product-total">   <hr className="divider mb-2 mt-6" /></td>
                                                        </tr>

                                                        <tr className="cart-subtotal bb-no ">
                                                            <td>
                                                                <b style={{ fontSize: '1.5rem' }}>Toplam Ödenecek</b>
                                                            </td>
                                                            <td>
                                                                <b style={{ fontSize: '1.5rem' }}>{toplamTutar < MyHelper().GetCargoLimit(parameterList) ? (parseFloat(toplamTutar) + parseFloat(MyHelper().GetCargoPrice(parameterList).toFixed(2))) : toplamTutar}₺</b>
                                                            </td>
                                                        </tr>
                                                        <tr className="bb-no">
                                                            <td style={{ fontSize: '1.5rem' }} className="product-name">
                                                                <hr className="divider mb-2 mt-2" />
                                                            </td>
                                                            <td style={{ fontSize: '1.5rem' }} className="product-total">   <hr className="divider mb-2 mt-2" /></td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>

                                                    </tfoot>
                                                </table>

                                            </Validator>


                                        </div>

                                        <h3 className="title text-uppercase ls-10">Ödeme Bilgileri</h3>

                                        <div className="col-md-12">
                                            <PaymentForm />
                                            <div className="form-group place-order">
                                                <div type="submit" onClick={CreateOrder} className="btn btn-dark btn-block btn-rounded">Ödeme Yap</div>
                                            </div>
                                        </div>
                                       

                                    </div></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* End of PageContent */}
            </main>


        </>
    )

    function AddressAndPayment() {
        return <>



            <div className="row">
                {
                    addressList.map((item) => {
                        return <AddressItem item={item} />

                    })
                }
            </div>


        </>
    }

    function AddressItem({ item }) {

        return <div className="col-sm-6 mb-4">
            <div className="icon-box-wrap">
                <div className="icon-box icon-colored-circle icon-border-box text-left">
                    <div className="icon-box-content">
                        <h4 className="icon-box-title">{item.ek_tittle}</h4>
                        <table className="address-table text-left">
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td>{item.ek_owner_name}</td>
                                </tr>
                                <tr >
                                    <th>Address:</th>
                                    <td>{item.ek_open_address}</td>
                                </tr>
                                <tr>
                                    <th>City:</th>
                                    <td>{item.ek_city}</td>
                                </tr>
                                <tr>
                                    <th>Country:</th>
                                    <td>{item.ek_country}</td>
                                </tr>
                                <tr>
                                    <th>Phone:</th>
                                    <td>{item.ek_phone}</td>
                                </tr>
                            </tbody>
                        </table>

                        <a onClick={() => {
                            addressList.map((a, index) => {
                                addressList[index].selected = false;
                                if (a == item) {
                                    addressList[index].selected = true;
                                }
                            })
                            setAddressList([...addressList]);
                        }}>{item.selected ? "Seçildi" : "Seç"}
                            <i className="w-icon-long-arrow-right" />
                        </a>
                    </div>
                </div>
            </div>
        </div>;

    }



    function UpdateUser() {

        setİsUserOnline(MyHelper().IsLogin());
        global.nameRefresh();
        DataManager().Users.GetAllAddress().then(r => {

            // alert(JSON.stringify(r))
            if (r.length != 0)
                r[0].selected = true;
            else
                MyHelper().AlertFillAll("Lütfen Adres Giriniz")

            setAddressList(r);

        })

    }

    function Example() {


        return (
            <>

                <Modal show={false} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>SMS Doğrulama</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Vazgeç
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}


export default class PaymentForm extends React.Component {
    state = {
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    };

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    render() {
        return (
            <div id="PaymentForm">
                <Cards
                    cvc={this.state.cvc}
                    expiry={this.state.expiry}
                    focused={this.state.focus}
                    name={this.state.name}
                    number={this.state.number}
                />
                <form>
                    <div className="row mt-4">

                        <div className="col-xs-6">
                            <div className="form-group">
                                <label>Kredi Kartı Numarası *</label>
                                <input
                                    type="tel"
                                    className="form-control form-control-md"
                                    name="number"
                                    id="input_credit_card_number"
                                    placeholder="Kart Numarası"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                />
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="form-group">
                                <label>İsim Soyisim *</label>
                                <input
                                    type="tel"
                                    className="form-control form-control-md"
                                    name="name"
                                    id="input_credit_card_name"
                                    placeholder="İsim Soyisim"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                />
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="form-group">
                                <label>Son Kullanma Tarihi *</label>
                                <div className="col-xs-12">

                                    <input
                                        type="tel"
                                        className="form-control form-control-md"
                                        name="expiry"
                                        id="input_credit_card_expiry"
                                        placeholder="Son Kullanma Tarihi"
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="form-group">
                                <label>CVC *</label>
                                <div className="col-xs-12">

                                    <input
                                        type="tel"
                                        className="form-control form-control-md"
                                        name="cvc"
                                        placeholder="CVC"
                                        id="input_credit_card_cvc"
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        );
    }
}