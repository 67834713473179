import logo from './logo.svg';



import Index from './Parodia/Thema/Wolmart/Index';
import "./App.css"

function App() {

  return  <Index />
}


export default App;
