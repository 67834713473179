import MyHelper from '../../controller/MyHelper';

const axios = require('axios');
export default function DBGroup() {

    function GetAllGroupList() {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_admin_option_group_list',
            })
                .then(function (response) {
                    // console.log(response);

                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function GetGroupDetail(id) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_group_detail',
                groupID: id,
            })
                .then(function (response) {
                    // console.log(response);

                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function GroupInsert(name, subGroup) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_group_insert',
                optionGroupName: name,
                subGroupList: subGroup,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function GroupUpdate(id, name) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_group_update',
                groupID: id,
                groupName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function GroupDelete(id) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_group_delete',
                groupID: id
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }


    function SubGroupInsert(id, name) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_sub_group_insert',
                parentID: id,
                groupName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function SubGroupUpdate(id, name) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_sub_group_update',
                optionSubGroupID: id,
                optionSubGroupName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function SubGroupDelete(id) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_sub_group_delete',
                parentID: id,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    const headers = {
        'Content-Type': 'text/plain'
    };

    return {
        GetAllGroupList, GetGroupDetail, GroupInsert, GroupUpdate, GroupDelete, SubGroupInsert, SubGroupUpdate, SubGroupDelete
    }
}