export default function BasketManager() {

    function AddProduct(product) {
        var bash = "[]"
        if (localStorage.getItem("kahvenin_delisi_basket") != undefined && localStorage.getItem("kahvenin_delisi_basket") != "undefined") {
            bash = localStorage.getItem("kahvenin_delisi_basket")
        }
        var items = JSON.parse(bash);

        product["index"] = items.length + 1;
        items.push(product)
        
        localStorage.setItem("kahvenin_delisi_basket", JSON.stringify(items));
    }

    function SetProducts(product) {


        
        localStorage.setItem("kahvenin_delisi_basket", JSON.stringify(product));
    }

    function Clear() {
        localStorage.setItem("kahvenin_delisi_basket", undefined);
    }

    function RemoveProduct(subItem) {
        var bash = "[]"
        if (localStorage.getItem("kahvenin_delisi_basket") != undefined && localStorage.getItem("kahvenin_delisi_basket") != "undefined") {
            bash = localStorage.getItem("kahvenin_delisi_basket")
        }
        var items = JSON.parse(bash);
        items.map((item, index) => {
            if (item.ek_id == subItem.ek_id) {
                items.splice(index, 1);
            }
        })
        localStorage.setItem("kahvenin_delisi_basket", JSON.stringify(items));
    }

    function groupBy(collection, property) {
        var i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }

    function GetAllProducts() {
        var bash = "[]"
        if (localStorage.getItem("kahvenin_delisi_basket") != undefined && localStorage.getItem("kahvenin_delisi_basket") != "undefined") {
            bash = localStorage.getItem("kahvenin_delisi_basket")
        }
        var items = JSON.parse(bash);

        return groupBy(items, "id");
    }

    function GetAllProductsNormal() {
        var bash = "[]"
        if (localStorage.getItem("kahvenin_delisi_basket") != undefined && localStorage.getItem("kahvenin_delisi_basket") != "undefined") {
            bash = localStorage.getItem("kahvenin_delisi_basket")
        }
        var items = JSON.parse(bash);

        return items;
    }

    function GetCount() {
        var bash = "[]"
        if (localStorage.getItem("kahvenin_delisi_basket") != undefined && localStorage.getItem("kahvenin_delisi_basket") != "undefined") {
            bash = localStorage.getItem("kahvenin_delisi_basket")
        }
        var items = JSON.parse(bash);

        return groupBy(items, "ek_id").length;
    }

    return {
        AddProduct, RemoveProduct, GetCount, GetAllProducts, SetProducts, GetAllProductsNormal, Clear
    }
}