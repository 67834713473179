import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import Loader from "react-loader-spinner";
import { Route, Router, useHistory } from "react-router-dom";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import Swal from 'sweetalert2'
import WrappedFrame from './../../../Cactus/Components/WrappedFrame'

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../../Ekmekk/global-data/selectors";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import AccountBody from "./AccountBody";

function AccountPage() {
  const parameterList = useRecoilValue(allParametersList);


  var history = useHistory();
  var inputName = useRef(null);
  var inputSurname = useRef(null);
  var inputMail = useRef(null);
  var inputPhone = useRef(null);
  var inputPassword = useRef(null);

  const [profileData, setProfileData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(false)
  const [themeColor, setThemeColor] = useState("")

  useEffect(() => {
    setPageSecurity(localStorage.getItem('kahvenin_delisi_user_id') == "undefined" ? false : true)
    localStorage.getItem('kahvenin_delisi_user_id') != "undefined" ? console.log("") : history.push('/auth')
  }, [])

  useEffect(() => {

    setThemeColor(MyHelper().GetThemeColor(parameterList));
    console.log(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])

  useEffect(() => {
    DataManager().Users.GetProfile().then(r => {

      setProfileData(r);
      // inputName.current.value = r.ek_user_name;
      // inputSurname.current.value = r.ek_user_surname;
      // inputMail.current.value = r.ek_mail;
      // inputPhone.current.value = r.ek_phone;
    })
  }, [])

  function ClickLogin() {
    DataManager().Users.UpdateProfile(inputName.current.value, inputSurname.current.value, inputMail.current.value, inputPhone.current.value).then((r) => {

      MyHelper().AlertSuccess();
    }).catch((r) => {
      alert("şifre yanlış")
    });
  }

  const [addressList, setAddressList] = useState([]);
  const [pageType, setPageType] = useState("list");
  const [selectedID, setSelectedID] = useState(-1);
  const [isAdress, setIsAdress] = useState(false)
  const [pageSecurity, setPageSecurity] = useState(false)


  var inputTittle = useRef(null);
  var inputOwnerName = useRef(null);
  var inputPhoneA = useRef(null);
  var inputOpenAddress = useRef(null);
  var inputCountry = useRef(null);
  var inputCity = useRef(null);
  var inputDistrict = useRef(null);
  var inputNeighborhood = useRef(null);

  useEffect(() => {

    DataManager().Users.GetAllAddress().then((r) => {
      console.log(r);
      setAddressList(r);
    })

  }, [])


  useEffect(() => {

    setThemeColor(MyHelper().GetThemeColor(parameterList));
    console.log(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])


  const [orderList, setOrderList] = useState([]);
  var history = useHistory();
  useEffect(() => {
    DataManager().Order.OrderUserList().then(r => {
      setOrderList([...r]);
      console.log(r)
    })
  }, [])

  const [selectedAdress, setSelectedAdress] = useState([])

  function InsertAddress() {
    if (selectedAdress.isUpdate == true) {
      DataManager().Users.UpdateAddress(selectedID, inputTittle.current.value, inputOwnerName.current.value, inputPhone.current.value, inputOpenAddress.current.value, inputCountry.current.value, inputCity.current.value, inputDistrict.current.value, inputNeighborhood.current.value).then((r) => {
        console.log(r);
        setSelectedAdress([])
      })
    }
    else {

      var newData = {

      }
      newData["ek_address_id"] = 1;
      newData["ek_city"] = inputCity.current.value;
      newData["ek_country"] = inputCountry.current.value;
      newData["ek_district"] = inputDistrict.current.value;
      newData["ek_neighborhood"] = inputNeighborhood.current.value;
      newData["ek_open_address"] = inputOpenAddress.current.value;
      newData["ek_owner_name"] = inputOwnerName.current.value;
      newData["ek_phone"] = inputPhone.current.value;
      newData["ek_tittle"] = inputTittle.current.value;
      addressList.push(newData);
      setAddressList([...addressList])
      setPageType("list");
      DataManager().Users.InsertAddress(inputTittle.current.value, inputOwnerName.current.value, inputPhone.current.value, inputOpenAddress.current.value, inputCountry.current.value, inputCity.current.value, inputDistrict.current.value, inputNeighborhood.current.value).then((r) => {
        console.log(r);
      })
    }

  }

  const PopupAdress = () => {



    return (
      <div style={{ display: isAdress ? "inherit" : "none" }} className="login-popup ">
        <div className="tab tab-nav-boxed tab-nav-center tab-nav-underline">
          <ul className="nav nav-tabs text-uppercase" role="tablist">
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="sign-in">
              <div className="form-group">
                <label> Adres Başlığı*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_tittle} type="text" className="form-control" ref={inputTittle} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> Telefon*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_phone} type="text" className="form-control" ref={inputPhoneA} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> Açık Adress*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_open_address} type="text" className="form-control" ref={inputOpenAddress} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> Teslim Edilecek Kişi*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_owner_name} type="text" className="form-control" ref={inputOwnerName} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> Üke*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_country} type="text" className="form-control" ref={inputCountry} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> İl*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_city} type="text" className="form-control" ref={inputCity} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> İlçe*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_district} type="text" className="form-control" ref={inputDistrict} name="username" id="username" required />
              </div>
              <div className="form-group">
                <label> Mahalle*</label>
                <input defaultValue={selectedAdress != [] && selectedAdress.ek_neighborhood} type="text" className="form-control" ref={inputNeighborhood} name="username" id="username" required />
              </div>
              <a onClick={() => InsertAddress()} className="btn btn-primary">{selectedAdress.isUpdate != undefined && selectedAdress.isUpdate == true ? "Güncelle" : "Oluştur"} </a>
            </div>
          </div>
        </div>

        <button title="Close (Esc)" type="button" className="mfp-close"><span>×</span></button></div>

    )
  }

  const getOrder = (orderId, bool) => {
    setSelectedOrder({
      orderId: orderId,
      boolen: bool
    })
  }

  function Logout() {
    Swal.fire({
      title: 'Çıkış yapmak istediğinize eminmisiniz?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Çıkış Yap',
      denyButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.isConfirmed) {
        MyHelper().LogOut();
        history.push("/")
      } else if (result.isDenied) {

      }
    })
  }










  function OnClick(item) {

    if(item.id == "btn_orders")
    {
      history.push("/account/orders");
    }
    else  if(item.id == "btn_address")
    {
      history.push("/account/address");
    }
    else  if(item.id == "btn_address")
    {
      history.push("/account/ticket");
    } 
    else  if(item.id == "btn_account_information")
    {
      history.push("/account/profile");
    }
    else  if(item.id == "btn_like")
    {
      history.push("/account/ticket");


    } 
    else  if(item.id == "btn_logout")
    {
      Logout();
    }
    else
    {
      alert(item.id)
    }
  }
  return <>
    <WrappedFrame onClick={OnClick} >
      <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />

      <AccountBody>
        {
          process.env.PUBLIC_URL
        }
        <div className="tab-content mb-6">
          <div className="tab-pane active in" id="account-dashboard">
           
            <div className="row">
              <div data-typeee="btn" id="btn_orders" className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                <a className="link-to-tab">
                  <div className="icon-box text-center">
                    <span className="icon-box-icon icon-orders">
                      <i className="w-icon-orders" />
                    </span>
                    <div className="icon-box-content">
                      <p className="text-uppercase mb-0">Siparişlerim</p>
                    </div>
                  </div>
                </a>
              </div>
              <div data-typeee="btn" id="btn_address" className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                <a  className="link-to-tab">
                  <div className="icon-box text-center">
                    <span className="icon-box-icon icon-address">
                      <i className="w-icon-map-marker" />
                    </span>
                    <div className="icon-box-content">
                      <p className="text-uppercase mb-0">Adres</p>
                    </div>
                  </div>
                </a>
              </div>
              <div data-typeee="btn" id="btn_account_information" className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                <a  className="link-to-tab">
                  <div className="icon-box text-center">
                    <span className="icon-box-icon icon-account">
                      <i className="w-icon-user" />
                    </span>
                    <div className="icon-box-content">
                      <p className="text-uppercase mb-0">Hesap Bilgilerim</p>
                    </div>
                  </div>
                </a>
              </div>
              <div data-typeee="btn" id="btn_like" className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                <a  className="link-to-tab">
                  <div className="icon-box text-center">
                    <span className="icon-box-icon icon-wishlist">
                      <i className="w-icon-heart" />
                    </span>
                    <div className="icon-box-content">
                      <p className="text-uppercase mb-0">Beğendiklerim</p>
                    </div>
                  </div>
                </a>
              </div>
              <div data-typeee="btn" id="btn_logout" className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                <a >
                  <div className="icon-box text-center">
                    <span className="icon-box-icon icon-logout">
                      <i className="w-icon-logout" />
                    </span>
                    <div className="icon-box-content">
                      <p className="text-uppercase mb-0">Çıkış Yap</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>

      </AccountBody>


    </WrappedFrame>
    
  </>
}



export default AccountPage


function GetStatus({ code }) {

  var color = "rgb(247, 66, 66)";
  var text = "";

  switch (code) {
    case -1:
      text = "İptal Edildi";
      color = "rgb(247, 66, 66)"
      break;
    case 0:
      text = "Onay Bekliyor";
      color = "gray"
      break;
    case 1:
      text = "Hazırlanıyor";
      color = "#6837a4"
      break;
    case 2:
      text = "Kargoya Verildi";
      color = "orange"
      break;
    case 3:
      text = "Teslim Edildi";
      color = "#17cc17"
      break;

  }


  return <td style={{ color: color }} className="order-status">{text} </td>
}