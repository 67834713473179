import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import WrappedFrame from "../../Components/WrappedFrame";
import AccountBody from "./AccountBody";

export default function OrdersDetail() {
    var { id } = useParams();
    var history = useHistory();
    function OnClick(item) {

        if (item.id == "btn_orders") {
            history.push("/account/orders");
        }
        else if (item.id == "btn_address") {
            history.push("/account/address");
        }
        else if (item.id == "btn_address") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_account_information") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_like") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_logout") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_address_save") {
            var inputUsernames = content.getElementsByTagName('input');
            var inputList = [];
            for (var i = 0, l = inputUsernames.length; i < l; i++) {
                inputList[inputUsernames[i].key] = inputUsernames[i].value;

            }
        }
        else {
            alert(item.id)
        }
    }

    const [order, setOrders] = useState([]);
    const [content, setContent] = useState([]);
    useEffect(() => {


        DataManager().Order.OrderDetail(id).then(r => {
            setOrders(r);
            console.log(r);
        })

    }, [])

    function SetContent(c) {
        setContent(c);

    }

    return <>
        <WrappedFrame content={SetContent} onClick={OnClick} >
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />
          <AccountBody activeTab="order">

                <div className="tab-content mb-6">
                    <div className="tab-pane active in" >
                        <div className="icon-box icon-box-side icon-box-light">
                            <span className="icon-box-icon icon-account mr-2">
                                <i className="w-icon-sharp" />
                            </span>
                            <div className="icon-box-content">
                                <h4 className="icon-box-title mb-0 ls-normal">#{order.ek_order_id} numaralı sipariş</h4>

                            </div>
                        </div>
                        <div className="row mt-4">

                            <div className="col-sm-8 mb-6" id="account-addresses">
                                <table className="shop-table wishlist-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name"><span>Ürün</span></th>
                                            <th />
                                            <th className="product-price"><span>Fiyat</span></th>
                                            <th className="product-stock-status"><span>Adeet</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        order.items == undefined ? "" :    order.items.map(item=>{
                                                return  <tr>
                                                <td className="product-thumbnail">
                                                    <div className="p-relative">
                                                        <a href="product-default.html">
                                                            <figure>
                                                                <img src={item.imagePath} alt="product" width={300} height={338} />
                                                            </figure>
                                                        </a>
                                                       
                                                    </div>
                                                </td>
                                                <td className="product-name">
                                                    <a href="product-default.html">
                                                        {item.ek_product_name}
                                                    </a>
                                                </td>
                                                <td className="product-price">
                                                    <ins className="new-price">{item.ek_price} ₺</ins>
                                                </td>
                                                <td className="product-stock-status">
                                                    <span className="wishlist-in-stock">{item.count}</span>
                                                </td>
                                            </tr>
                                       
                                            })
                                        }
                                       
                                    </tbody>
                                </table>

                            </div>

                            <div className="col-sm-4 mb-6" id="account-addresses">
                                <div className="ecommerce-address billing-address pr-lg-8">
                                    <h4 className="title title-underline ls-25 font-weight-bold">Kargo adresi</h4>
                                    <address className="mb-4">
                                        <table className="address-table">
                                            <tbody>
                                                <tr>
                                                    <th>Adres Başlığı:</th>
                                                    <td>{order.ek_tittle}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name:</th>
                                                    <td>{order.ek_owner_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Address:</th>
                                                    <td>{order.ek_open_address}</td>
                                                </tr>
                                                <tr>
                                                    <th>City:</th>
                                                    <td>{order.ek_city}</td>
                                                </tr>
                                                <tr>
                                                    <th>Country:</th>
                                                    <td>{order.ek_country}</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone:</th>
                                                    <td>{order.ek_phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </address>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </AccountBody>


        </WrappedFrame>

    </>
}