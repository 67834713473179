export default function AccountBody(props) {
    return <>
        <div className="page-header">
            <div className="container">
                <h1 className="page-title mb-0">Hesabım</h1>
            </div>
        </div>
        <div className="my-account">
            <div className="page-content pt-2">
                <div className="container">
                    <div className="tab tab-vertical row gutter-lg">
                        <ul className="nav nav-tabs mb-6" role="tablist">
                            <li className="nav-item">
                                <a data-typeee="btn" id="btn_orders" className={"nav-link " + (props.activeTab == "order" ? "active" : "")}>Siparişlerim</a>
                            </li>
                            <li className="nav-item">
                                <a data-typeee="btn" id="btn_address" className={"nav-link " + (props.activeTab == "address" ? "active" : "")}>Adreslerim</a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (props.activeTab == "ticket" ? "active" : "")}>Destek Taleplerim</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link">Beğendiklerim</a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (props.activeTab == "account" ? "active" : "")}data-typeee="btn" id="btn_account_information">Hesap Bilgilerim</a>
                            </li>
                            <li className="link-item">
                                <a data-typeee="btn" id="btn_logout">Çıkış Yap</a>
                            </li>
                        </ul>
                        {
                            props.children
                        }
                    </div>
                </div>

            </div>


        </div>

    </>
}