import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from '../Cactus/Pages/HomePage'
import Shop from '../Cactus/Pages/Shop/ShopPage'
import ProductDetail from '../Cactus/Pages/Shop/ProductDetail'
import BlogPage from '../Cactus/Pages/Blog/BlogPage'
import BlogDetail from '../Cactus/Pages/Blog/BlogDetail'
import ShoppingCard from '../Cactus/Pages/Shop/ShoppingCard'
import AccountPage from '../Cactus/Pages/Account/AccountPage'
import { CheckOut } from '../../Thema/Cactus/Pages/Shop/Checkout'
import PaymentSuccess from '../Cactus/Pages/Shop/PaymentSuccess'

import Header from '../Cactus/Components/Header'
import Footer from '../Cactus/Components/Footer'
import MobileMenu from '../Cactus/Components/MobileMenu'
import Authentication from '../Cactus/Components/Authentication'
import AdminLogin from '../../../Ekmekk/page/Admin/Login/admin_login'

import ScrollToTop from '../Cactus/util/javascript/ScrollToTop'
import history from '../Cactus/util/javascript/browserHistory'
import ContactPage from '../Cactus/Pages/Contact/ContactPage'
import WishListPage from '../Cactus/Pages/WishListPage/WishListPage'
import { PrivacyPolicy } from '../Cactus/Components/PrivacyPolicy'
import { AboutUs } from '../Cactus/Components/AboutUs'
import { SaleContract } from '../Cactus/Components/SaleContract'

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../Ekmekk/global-data/selectors";
import { renderHeader } from "../../../Ekmekk/global-data/atom";
import Address from '../Cactus/Pages/Account/Address'
import AccountInformation from '../Cactus/Pages/Account/AccountInformation'
import Orders from '../Cactus/Pages/Account/Orders'
import AddressDetail from '../Cactus/Pages/Account/AddressDetail'
import AddressInsert from '../Cactus/Pages/Account/AddressInsert'
import OrdersDetail from '../Cactus/Pages/Account/OrdersDetail'
import "./deneme.css"
import { useEffect } from 'react'
function Index() {

  useEffect(()=>{
    document.documentElement.style.setProperty('--mainColor',parameterList.filter(item => item.ek_key == "themaColor")[0].ek_value);
  },[])

  const parameterList = useRecoilValue(allParametersList);
  const [render, setRender] = useRecoilState(renderHeader)
  return (
    <Router history={history}>
      <ScrollToTop />
      {render && <Header />}
      <MobileMenu />
      <Switch>

        <Route path='/' exact component={Home}>

        </Route>

        <Route path='/shop/:id' component={Shop} />
        <Route path='/shop' component={Shop} />
    
        <Route path='/product/:id' component={ProductDetail} />
        <Route path='/shoppingcard' component={ShoppingCard} />
        <Route path='/blogs' component={BlogPage} />
        <Route path='/blogdetail/:id' component={BlogDetail} />
        <Route path='/auth' component={Authentication} />
        <Route path='/account' exact component={AccountPage} />
        <Route path='/account/address' exact component={Address} />
        <Route path='/account/address/insert' exact component={AddressInsert} />
        <Route path='/account/address/:id' exact component={AddressDetail} />
        <Route path='/account/orders' exact component={Orders} />
        <Route path='/account/orders/:id' exact component={OrdersDetail} />
        <Route path='/account/profile' exact component={AccountInformation} />
        <Route path='/contact' component={ContactPage} />
        <Route path='/wishlist' component={WishListPage} />
        <Route path='/check-out' component={CheckOut} />
        <Route path='/payment-success' component={PaymentSuccess} />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/sale-contract' component={SaleContract} />

      </Switch>
      <Footer />

    </Router>
  )


}



export default Index;


// function AdminRouter() {
//   return <>
//     <AdminBody>

//       {/* <Route exact path="/admin/login">
//         <AdminLogin />
//       </Route> */}
//       <Route path="/admin/home">
//         <AdminHome />
//       </Route>
//       <Route path="/admin/products/update/:id">
//         <AdminProductUpdate />
//       </Route>
//       <Route path="/admin/products/insert">
//         <AdminProductInsert />
//       </Route>
//       <Route exact path="/admin/products">
//         <AdminProducts />
//       </Route>

//       <Route path="/admin/groups/insert">
//         <AdminGroupInsert />
//       </Route>
//       <Route path="/admin/groups/update/:id">
//         <AdminGroupUpdate />
//       </Route>
//       <Route exact path="/admin/groups">
//         <AdminGroups />
//       </Route>

//       <Route path="/admin/category/insert">
//         <AdminCategoryInsert />
//       </Route>
//       <Route path="/admin/category/update/:id">
//         <AdminCategoryUpdate />
//       </Route>
//       <Route exact path="/admin/category">
//         <AdminCategory />
//       </Route>


//       <Route path="/admin/orders/detail/:id">
//         <AdminOrderDetail />
//       </Route>
//       <Route exact path="/admin/orders">
//         <AdminOrders />
//       </Route>

//       <Route path="/admin/blog/update/:id">
//         <AdminBlogUpdate />
//       </Route>
//       <Route path="/admin/blog/insert">
//         <AdminBlogInsert />
//       </Route>
//       <Route path="/admin/blog">
//         <AdminBlog />
//       </Route>


//       <Route path="/admin/settings">
//         <AdminSettings />
//       </Route>
//       <Route path="/admin/media">
//         <AdminMMedia />
//       </Route>



//     </AdminBody>
//   </>
// }