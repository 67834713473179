import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment'

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../Ekmekk/global-data/selectors";
import { searchState, categoryState } from "../../../../Ekmekk/global-data/atom";

import DataManager from "../../../../Ekmekk/database/DataManager";
import MyHelper from "../../../../Ekmekk/controller/MyHelper";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

const HomePage = () => {
  const parameterList = useRecoilValue(allParametersList);

  const [entryDate, setEntryDate] = useState(new Date());
  const [exitDate, setExitDate] = useState(moment(entryDate).add(1, 'days'));
  const [blogList, setBlogList] = useState();


  const [searchDate, setSearchDate] = useRecoilState(searchState)

  const [topPage, setTopPage] = useState(1);
  const [firstAllItemCount, setFirstAllItemCount] = useState(0)
  const [maxItemPerPage, setMaxItemPerPage] = useState(9)
  const [pageIndex, setPageIndex] = useState(0)

  const [productList, setProductList] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [categoryList, setCategoryList] = useRecoilState(categoryState)

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState("Tümü");

  var history = useHistory();

  function groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const [themeColor, setThemeColor] = useState("")

  const DateFormatter = (date) => {
    let data = moment(date).format('L').split('/')
    return data[2] + "-" + data[0] + "-" + data[1]
  }

  useEffect(() => {
    setThemeColor(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])

  useEffect(() => {
    DataManager().Product.GetAllProduct(searchDate == {} ? "" : DateFormatter(entryDate), searchDate == {} ? "" : DateFormatter(exitDate)).then((r) => {
      // alert(JSON.stringify(r))
      console.log(r);
      var categoryBash = groupBy(r, "ek_category_name");
      setProductList(r);
      setListProduct([...r])
      setTimeout(function () { setLoading(false) }, 500);

      setFirstAllItemCount(r.length);

      setTopPage((Math.ceil(r.length / maxItemPerPage)))
    })

  }, [])

  function GetSortOrder(prop) {
    return function (a, b) {
      if (parseInt(a[prop]) < parseInt(b[prop])) {
        return 1;
      } else if (parseInt(a[prop]) > parseInt(b[prop])) {
        return -1;
      }
      return 0;
    }
  }

  function GetSortOrder2(prop) {
    return function (a, b) {
      if (parseInt(a[prop]) > parseInt(b[prop])) {
        return 1;
      } else if (parseInt(a[prop]) < parseInt(b[prop])) {
        return -1;
      }
      return 0;
    }
  }

  const [sliderData, setSliderData] = useState([])
  const [campaignData, setCampaignData] = useState([])

  function ChangeSearchText(e) {
    var filteredList = productList.filter(item => item.ek_product_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
    setListProduct(filteredList);
    //listProduct
  }
  useEffect(() => {
    DataManager().Blog.List().then((r) => {
      setBlogList(r);
      console.log(r)
    })

    setSliderData(JSON.parse(((fromBinary(parameterList.filter(item => item.ek_key == "slider_data_new")[0].ek_value)))))
    setCampaignData(JSON.parse(((fromBinary(parameterList.filter(item => item.ek_key == "campaign_data_new")[0].ek_value)))))



  }, [])

  function GetCampaignData(index, variable) {
    try {
      return campaignData[index][variable];
    }
    catch (e) {
      return "";
    }

  }


  function fromBinary(encoded) {
    var binary = atob(encoded)
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }

  return (
    <>

      <main className="main">
        <div className="container">
          <div className="intro-wrapper">
            <div className="row">
              <div className="col-md-8 mb-4">

                <OwlCarousel
                  className="owl-theme animation-slider owl-dot-inner owl-loaded owl-drag"
                  loop
                  nav={false}
                  items={1}
                  autoWidth={false}
                  margin={10}
                  dots={true}
                >

                  {
                    sliderData.map((item) => {
                      return <div className="intro-slide intro-slide3 banner banner-fixed br-sm w-100" style={{ backgroundImage: `url(${item.imageURL})`, backgroundColor: '#5D5E62' }}>
                        <div className="banner-content y-50 text-center w-100">
                          <div>


                            <div className="banner-price-info text-uppercase text-default">
                              <strong className="text-dark"></strong> <div dangerouslySetInnerHTML={{ __html: item.text }} />
                            </div>
                            <a onClick={() => history.push('/shop')} className="btn btn-dark btn-rounded">Hemen Sipariş Ver</a>
                          </div>
                        </div>
                      </div>
                    })
                  }


                </OwlCarousel>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 col-xs-6 mb-4">
                    <div className="category-banner banner banner-fixed br-sm">
                      <figure>
                        <img src={GetCampaignData(0, "imageURL")} className="img-fluid" alt="Category" style={{ backgroundColor: '#605959', maxWidth: 330, maxHeight: 239 }} width={330} height={239} />
                      </figure>
                      <div className="banner-content">
                        <h3 className="banner-title text-white text-capitalize ls-25"><div dangerouslySetInnerHTML={{ __html: GetCampaignData(0, "text") }} /> </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xs-6 mb-4">
                    <div className="category-banner banner banner-fixed br-sm">
                      <figure>
                        <img src={GetCampaignData(1, "imageURL")} alt="Category" style={{ backgroundColor: '#eff5f5', maxWidth: 330, maxHeight: 239 }} width={330} height={239} />
                      </figure>
                      <div className="banner-content">
                        <h3 className="banner-title text-white text-capitalize ls-25 mb-3"><div dangerouslySetInnerHTML={{ __html: GetCampaignData(1, "text") }} />  </h3>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="title-link-wrapper title-deals appear-animate mb-4 fadeIn appear-animation-visible" style={{ animationDuration: '1.2s' }}>
            <h2 className="title title-link">Günün Teklifleri</h2>
            {/* <div className="product-countdown-container font-size-sm text-white bg-secondary align-items-center mr-auto">
              <label>Offer Ends in: </label>
              <div className="product-countdown countdown-compact ml-1 font-weight-bold is-countdown" data-until="+10d" data-relative="true" data-compact="true"><span className="countdown-row countdown-amount">9 days,  23 : 34 : 28</span></div>
            </div> */}
            <a onClick={() => history.push('/shop')} className="ml-0">Daha Fazla Ürün<i className="w-icon-long-arrow-right" /></a>
          </div>

          <OwlCarousel
            className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-b mb-6"
            loop
            nav={true}
            items={1}
            dots={false}
            autoWidth={false}
            margin={10}
            responsive={
              {
                0: {
                  items: 2,
                },
                600: {
                  items: 3,
                },
                1000: {
                  items: 5,
                },
              }
            }
          >
            {
              productList && productList.map(product => {
                return (
                  <div onClick={() => history.push('/product/' + product.ek_product_id)} className="product-wrap">
                    <div className="product text-center">
                      <figure className="product-media">
                        <a >
                          <img src={product.ek_image_path} alt="Product" style={{ objectFit: "contain", height: 200 }} />
                        </a>

                      </figure>
                      <div className="product-details">
                        <h4 className="product-name"><a >{product.ek_product_name}</a></h4>
                        <div className="ratings-container">
                          <div className="ratings-full">
                            <span className="ratings" style={{ width: '80%' }} />
                            <span className="tooltiptext tooltip-top" />
                          </div>

                        </div>
                        <div className="product-price">
                          <ins className="new-price">{product.price} ₺</ins><del className="old-price">{parseInt(product.price) + 50} ₺</del>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }


            {/* End of Product Wrap */}
          </OwlCarousel>

          <OwlCarousel
            className=""
            dots={false}
            responsive={
              {
                0: {
                  items: 3,
                },
                600: {
                  items: 3,
                },
                1000: {
                  items: 6,
                },
              }
            }
          >



          </OwlCarousel>
          {/* End of Icon Category Wrapper */}
          <div className="category-banner-wrapper mt-6 row mb-5">
            <div className="col-md-6 mb-4">
              <div className="banner banner-fixed br-sm">
                <figure>
                  <img src={GetCampaignData(2, "imageURL")} alt="Category Banner" style={{ backgroundColor: '#565960', maxWidth: 680, maxHeight: 180 }} width={680} height={180} />
                </figure>
                <div className="banner-content y-50">

                  <h3 className="banner-title text-white text-capitalize"><div dangerouslySetInnerHTML={{ __html: GetCampaignData(2, "text") }} /></h3>


                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="banner banner-fixed br-sm">
                <figure>
                  <img src={GetCampaignData(3, "imageURL")} alt="Category Banner" style={{ backgroundColor: '#565960', maxWidth: 680, maxHeight: 180 }} width={680} height={180} />
                </figure>
                <div className="banner-content y-50">


                  <h3 className="banner-title text-white text-capitalize"><div dangerouslySetInnerHTML={{ __html: GetCampaignData(3, "text") }} /></h3>


                </div>
              </div>
            </div>
          </div>



        </div>

        <div className="container mt-6">

          {/* End of Banner Simple */}
          <div className="title-link-wrapper appear-animate mb-4">
            <a >Daha Fazla Ürün<i className="w-icon-long-arrow-right" /></a>
          </div>
          <div className="title-link-wrapper title-deals appear-animate mb-4 fadeIn appear-animation-visible" style={{ animationDuration: '1.2s' }}>
            <h2 className="title title-link pt-1">Favori Ürünler </h2>

            {/* <div className="product-countdown-container font-size-sm text-white bg-secondary align-items-center mr-auto">
              <label>Offer Ends in: </label>
              <div className="product-countdown countdown-compact ml-1 font-weight-bold is-countdown" data-until="+10d" data-relative="true" data-compact="true"><span className="countdown-row countdown-amount">9 days,  23 : 34 : 28</span></div>
            </div> */}
            <a onClick={() => history.push('/shop')} className="ml-0">Daha Fazla Ürün<i className="w-icon-long-arrow-right" /></a>
          </div>

          <OwlCarousel
            className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-b mb-6"
            loop
            nav={true}
            responsive={
              {
                0: {
                  items: 2,
                },
                600: {
                  items: 3,
                },
                1000: {
                  items: 5,
                },
              }
            }
            dots={false}
            autoWidth={false}
            margin={10}
          >
            {
              productList && productList.map(product => {
                return (
                  <div onClick={() => history.push('/product/' + product.ek_product_id)} className="product-wrap">
                    <div className="product text-center">
                      <figure className="product-media">
                        <a >
                          <img src={product.ek_image_path} alt="Product" style={{ objectFit: "contain", height: 200 }} />
                        </a>

                      </figure>
                      <div className="product-details">
                        <h4 className="product-name"><a >{product.ek_product_name}</a></h4>
                        <div className="ratings-container">
                          <div className="ratings-full">
                            <span className="ratings" style={{ width: '80%' }} />
                            <span className="tooltiptext tooltip-top" />
                          </div>

                        </div>
                        <div className="product-price">
                          <ins className="new-price">{product.price} ₺</ins><del className="old-price">{parseInt(product.price) + 50} ₺</del>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </OwlCarousel>

          <div className="banner banner-simple  br-sm mb-10" style={{ backgroundImage: `url(${GetCampaignData(4, "imageURL")})`, backgroundColor: '#414548' }}>
            <div className="banner-content align-items-center">

              <div className="banner-info mb-0">
                <h3 className="banner-title text-white font-weight-normal ls-25">
                  <strong> <div dangerouslySetInnerHTML={{ __html: GetCampaignData(4, "text") }} /> </strong>
                </h3>


              </div>
            </div>
            <figure className="skrollable">
              <img src="assets/images/demos/demo5/banners/ski.png" alt="Banner" data-bottom-top="transform: translateY(5vh);" data-top-bottom="transform: translateY(-5vh);" />
            </figure>
          </div>



          {/* <div className="title-link-wrapper  mt-10 mb-4">
            <h2 className="title title-link pt-1">Tercih edilenler</h2>
            <a    className="ls-normal">Daha fazla ürün<i className="w-icon-long-arrow-right" /></a>
          </div>


          
          <h2 className="title text-left title-client  mb-5 appear-animate">Müşterilerimiz</h2>

          <OwlCarousel
            className=" brands-wrapper br-sm "
            dots={false}
            items={6}
          >
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
            <figure>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={310} height={180} />
            </figure>
          </OwlCarousel>
          End of Brands Wrapper */}


        </div>
        {/* <section className="grey-section appear-animate pt-10 pb-10 fadeIn appear-animation-visible" style={{ animationDuration: '1.2s' }}>
          <div className="container mb-2">
            <div className="title-link-wrapper mb-4">
              <h2 className="title title-link">Featured Products</h2>
              <a   >More Products<i className="w-icon-long-arrow-right" /></a>
            </div>
            <div className="row grid grid-type">
              <div className="grid-item grid-item-single">
                <div className="product product-single">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="product-gallery mb-0">
                        <figure className="product-image" style={{ position: 'relative', overflow: 'hidden', cursor: 'pointer' }}>
                          <img src="assets/images/demos/demo5/products/2-1.jpg" data-zoom-image="assets/images/demos/demo5/products/2-1.jpg" alt="Product Image" width={800} height={900} />
                          <img role="presentation" alt="" src="assets/images/demos/demo5/products/2-1.jpg" className="zoomImg" style={{ position: 'absolute', top: '-513.319px', left: '-165.043px', opacity: 0, width: '880px', height: '990px', border: 'medium none', maxWidth: 'none', maxHeight: 'none' }} /></figure>
                      </div>
                    </div>
                    <div className="col-md-6 pr-md-4 mt-4 mt-md-0">
                      <div className="product-details scrollable pl-0">
                        <h2 className="product-title mb-1"><a>Men's Season
                          Blue Clothes</a></h2>
                        <hr className="product-divider" />
                        <div className="product-price mb-2"><ins className="new-price ls-50">$150.00 -
                          $180.00</ins></div>
                        <div className="ratings-container mb-4">
                          <div className="ratings-full">
                            <span className="ratings" style={{ width: '80%' }} />
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          <a    className="rating-reviews">(3 Reviews)</a>
                        </div>
                        <div className="product-form product-variation-form product-size-swatch mb-3">
                          <label className="mb-1">Size:</label>
                          <div className="flex-wrap d-flex align-items-center product-variations">
                            <a    className="size">Small</a>
                            <a    className="size">Medium</a>
                            <a    className="size">Large</a>
                            <a    className="size">Extra Large</a>
                          </div>
                          <a    className="product-variation-clean" style={{ display: 'none' }}>Clean All</a>
                        </div>
                        <div className="product-variation-price">
                          <span />
                        </div>
                        <div className="product-form pt-4">
                          <div className="product-qty-form mb-2 mr-2">
                            <div className="input-group">
                              <input className="quantity form-control" type="number" min={1} max={10000000} />
                              <button className="quantity-plus w-icon-plus" />
                              <button className="quantity-minus w-icon-minus" />
                            </div>
                          </div>
                          <button className="btn btn-primary btn-cart disabled">
                            <i className="w-icon-cart" />
                            <span>Add to Cart</span>
                          </button>
                        </div>
                        <div className="social-links-wrapper mt-1">
                          <div className="social-links">
                            <div className="social-icons social-no-color border-thin">
                              <a    className="social-icon social-facebook w-icon-facebook" />
                              <a    className="social-icon social-twitter w-icon-twitter" />
                              <a    className="social-icon social-pinterest fab fa-pinterest-p" />
                              <a    className="social-icon social-whatsapp fab fa-whatsapp" />
                            </div>
                          </div>
                          <span className="divider d-xs-show" />
                          <div className="product-link-wrapper d-flex">
                            <a    className="btn-product-icon btn-wishlist w-icon-heart"><span /></a>
                            <a    className="btn-product-icon btn-compare btn-icon-left w-icon-compare"><span /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a>
                      <img src="assets/images/demos/demo5/products/2-2.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Top Rating Helmet</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '80%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$34.99</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-3.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Smartphone Electronic Charger</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '80%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$35.00</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-4.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Skate Pan</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '80%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$50.99</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-5.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Blue Ski Boots</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '100%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$88.00</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-6.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Dumbells</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '100%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$59.00</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-7.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Professional Perfect Camera</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '100%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$123.00</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-8.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Soft Sound Marker</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '100%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$39.99</ins>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-item-widget">
                <div className="product product-widget">
                  <figure className="product-media">
                    <a >
                      <img src="assets/images/demos/demo5/products/2-9.jpg" alt="Product" width={300} height={338} />
                    </a>
                  </figure>
                  <div className="product-details">
                    <h4 className="product-name">
                      <a >Roller Skates</a>
                    </h4>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: '100%' }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                    </div>
                    <div className="product-price">
                      <ins className="new-price">$66.99</ins>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="container mt-10 pt-2">
          <div className="title-link-wrapper mb-4">
            <h2 className="title title-link title-blog">Blog Sayfasından</h2>
            <a className="font-weight-bold font-size-normal ls-normal">Blog
              Sayfasına</a>
          </div>

          <OwlCarousel
            className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-b mb-6"
            dots={false}
            responsive={
              {
                0: {
                  items: 1,
                },
                600: {

                  items: 3,
                },
                1000: {
                  items: 5,
                },
              }
            }
          >
            {
              blogList && blogList.map(blog => {
                return (
                  <div onClick={() => history.push('/blogdetail/' + blog.ek_blog_id)} className="post text-center overlay-zoom ml-2">
                    <figure className="post-media br-sm">
                      <a >
                        <img src={blog.ek_image} alt="Post" style={{ backgroundColor: '#828896' }} width={280} height={180} />
                      </a>
                    </figure>
                    <div className="post-details">
                      <div className="post-meta">
                        <a className="post-author">Admin</a>
                        - <a className="post-date mr-0">03.05.2021</a>
                      </div>
                      <h4 className="post-title"><a >{blog.ek_tittle}</a></h4>
                      <a onClick={() => history.push('/blogdetail/' + blog.ek_blog_id)} className="btn btn-link btn-dark btn-underline">Devamını Oku<i className="w-icon-long-arrow-right" /></a>
                    </div>

                  </div>
                )
              })
            }
          </OwlCarousel>
          {/* Post Wrapper */}
        </div>

      </main>
    </>
  )
}

export default HomePage
