import MyHelper from '../../controller/MyHelper';

const axios = require('axios');
export default function DBAuth() {

  function AdminLogin(mail, password) {
    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'adminLogin',
        username: mail,
        password: password,
      })
        .then(function (response) {
          console.log(response);
          if (response.data.response) {
            ok(response.data);
          }
          else {
            fail(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  function Login(mail, password) {
    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'login',
        mail: mail,
        password: password,
      })
        .then(function (response) {
          console.log(response);
          if (response.data.response) {
            ok(response.data);
          }
          else {
            fail(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  function Register(name, surname, mail, phone, password) {
    return new Promise((ok, fail) => {

      axios.post(MyHelper().URLwithService(), {
        command: 'register',
        user_name: name,
        user_surname: surname,
        phone: mail,
        mail: phone,
        password: password,

      })
        .then(function (response) {
          if (response.data.response) {
            ok(response.data);
          }
          else {
            fail(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }
  function RememberPassword() {

  }

  return {
    AdminLogin, Login, Register, RememberPassword
  }
}