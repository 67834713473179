import MyHelper from '../../controller/MyHelper';

const axios = require('axios');
export default function DBCategory() {

    function GetAllCategory() {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_category_list',
            })
                .then(function (response) {
                    // console.log(response);

                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function Insert(name) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_category_insert',
                categoryName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function Update(id, name) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_category_update',
                categoryID: id,
                categoryName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function Remove(id) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_category_delete',
                categoryID: id
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    const headers = {
        'Content-Type': 'text/plain'
    };


    return {
        GetAllCategory, Insert, Update, Remove
    }
}