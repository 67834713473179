import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import WrappedFrame from "../../Components/WrappedFrame";
import AccountBody from "./AccountBody";

export default function AddressDetail() {
    var {id} = useParams();
    var history = useHistory();
    function OnClick(item) {

        if (item.id == "btn_orders") {
            history.push("/account/orders");
        }
        else if (item.id == "btn_address") {
            history.push("/account/address");
        }
        else if (item.id == "btn_address") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_account_information") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_like") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_logout") {
            history.push("/account/ticket");
        } 
        else if (item.id == "btn_address_remove") {
           DataManager().Users.RemoveAddress(id).then(()=>{
               MyHelper().AlertSuccess()
                history.push("/account/address");
           })
        }
        else if (item.id == "btn_address_save") {
            var inputUsernames = content.getElementsByTagName('input');
            var inputList = [];
            for (var i = 0, l = inputUsernames.length; i < l; i++) {
                inputList[inputUsernames[i].key] = inputUsernames[i].value;

            }
        }
        else {
            alert(item.id)
        }
    }

    const [address, setAddress] = useState([]);
    const [content, setContent] = useState([]);
    useEffect(() => {

        alert(id);

    }, [])

    function SetContent(c) {
        setContent(c);
        DataManager().Users.GetAllAddress().then(r => {
            setAddress(r);
            var inputUsernames = c.getElementsByTagName('input');
            var inputList = [];
            for (var i = 0, l = inputUsernames.length; i < l; i++) {
                inputList[inputUsernames[i].id] = inputUsernames[i];

            }
            var indeex = 0;
            r.map((item,index)=>{
                if(item.ek_address_id == id)
                {indeex = index;

                }
            })
            inputList["ek_tittle"].value = r[indeex]["ek_address_id"];
            inputList["ek_tittle"].value = r[indeex]["ek_tittle"];
            inputList["ek_open_address"].value = r[indeex]["ek_open_address"];
            inputList["ek_country"].value = r[indeex]["ek_country"];
            inputList["ek_city"].value = r[indeex]["ek_city"];
            inputList["ek_district"].value = r[indeex]["ek_district"];
            inputList["ek_neighborhood"].value = r[indeex]["ek_neighborhood"];
            inputList["ek_owner_name"].value = r[indeex]["ek_owner_name"];
            inputList["ek_phone"].value = r[indeex]["ek_phone"];
        })
    }

    return <>
        <WrappedFrame content={SetContent} onClick={OnClick} >
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />
         <AccountBody activeTab="address">

                <div className="tab-content mb-6">
                    <div className="tab-pane active in" id="account-details">
                        <div className="icon-box icon-box-side icon-box-light">
                            <span className="icon-box-icon icon-account mr-2">
                                <i className="w-icon-user" />
                            </span>
                            <div className="icon-box-content">
                                <h4 className="icon-box-title mb-0 ls-normal">Adres Güncelle</h4>
                            </div>
                        </div>
                        <div className="form account-details-form" >
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="firstname">Adres Başlığı *</label>
                                        <input type="text" id="ek_tittle" name="firstname" placeholder="John" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Teslimat Yapılacak Kişinin Adı *</label>
                                        <input type="text" id="ek_owner_name" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Telefon</label>
                                        <input type="text" id="ek_phone" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                              
                                
                            </div>

                            <h4 class="title title-password ls-25 font-weight-bold">Password change</h4>

                            <div className="row">
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Ülke *</label>
                                        <input type="text" id="ek_country" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">İl *</label>
                                        <input type="text" id="ek_city" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">İlçe*</label>
                                        <input type="text" id="ek_district" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Mahalle *</label>
                                        <input type="text" id="ek_neighborhood" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Açık adres</label>
                                        <input type="text" id="ek_open_address" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                            </div>
                            <button id="btn_address_remove" type="submit" className="btn btn-danger btn-rounded btn-sm mb-4 mr-2">Sil</button>
                            <button id="btn_address_save" type="submit" className="btn btn-dark btn-rounded btn-sm mb-4">Kaydet</button>
                        </div>
                    </div>

                </div>
            </AccountBody>


        </WrappedFrame>

    </>
}