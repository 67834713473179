import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";

export default function Counter({ data, selectedItem, limit, color,productID }) {
    const [count, SetCount] = useState(1);
    var say = 0;
    useEffect(() => {

        if (selectedItem != undefined) {
            SetCount(parseInt(selectedItem + ""));
        }
    }, [])

    function PlusMinus(direction) {

       data(direction)


    }
    return <>
        <div class="input-group">
            <input class="quantity form-control" type="number" min="1" value={selectedItem} max="10000000" />
            <button id="counter_plus" data-product={productID} onClick={() => { PlusMinus(1) }} class="quantity-plus w-icon-plus"></button>
            <button id="counter_minus" data-product={productID} onClick={() => { PlusMinus(-1) }} class="quantity-minus w-icon-minus"></button>
        </div>
        {/* <div className="plusminus" style={{color : "" + color +""}} onClick={() => { PlusMinus(-1) }}><FontAwesomeIcon icon={faMinus} /></div>
        <div className="plusminus-text" style={{ width: 30 }}>{count}</div>
        <div className="plusminus" style={{color : "" + color +""}} onClick={() => { PlusMinus(1) }}><FontAwesomeIcon icon={faPlus} /></div> */}
    </>
}