import MyHelper from '../../controller/MyHelper';

const axios = require('axios');
export default function DBBlog() {
    function Insert(tittle, tag, htmlData, image) {
        return new Promise((ok, fail) => {
            // alert(image);
            axios.post(MyHelper().URLwithService(), {
                command: 'blog_insert',
                tag: tag,
                tittle: tittle,
                htmlData: htmlData,
                imageData: image,
            })
                .then(function (response) {
                    ok(response.data);
                    console.log(response);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Update(id, tittle, tag, htmlData, image) {
        return new Promise((ok, fail) => {

            axios.post(MyHelper().URLwithService(), {
                command: 'blog_update',
                tag: tag,
                blogID: id,
                tittle: tittle,
                htmlData: htmlData,
                imageData: image,
            })
                .then(function (response) {
                    ok(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Remove(id) {
        return new Promise((ok, fail) => {

            axios.post(MyHelper().URLwithService(), {
                command: 'blog_delete',
                blogID: id,
            })
                .then(function (response) {
                    ok(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function List() {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'blog_list',
            })
                .then(function (response) {
                    ok(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Detail(id) {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'blog_detail',
                blog_id: id,
            })
                .then(function (response) {
                    ok(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }


    return {
        List, Insert, Remove, Update, Detail
    }
}