import { useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../../../../Ekmekk/component/footer";
import Header from "../../../../Ekmekk/component/header";
import MyHelper from "../../../../Ekmekk/controller/MyHelper";
import DataManager from "../../../../Ekmekk/database/DataManager";

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../Ekmekk/global-data/selectors";

function Authentication() {
  const parameterList = useRecoilValue(allParametersList);

  var history = useHistory();

  const [themeColor, setThemeColor] = useState("")
  const [form, setForm] = useState("login")

  useEffect(() => {

    setThemeColor(MyHelper().GetThemeColor(parameterList));
    console.log(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])

  function ClickLogin() {
    DataManager().Auth.Login(email.current.value, password.current.value).then((r) => {
      if (r.response) {
        Swal.fire('Giriş yapıldı', '', 'success')
        MyHelper().SetLogin(r.userID, r.name + " " + r.surname);
        history.push('/shop')
        //ClickEvent();
      }
    }).catch((r) => {
      Swal.fire('Şifre Yanlış', '', 'error')
    });
  }



  function ClickRegister() {
    DataManager().Auth.Register(inputName.current.value, inputSurname.current.value, inputMail.current.value, inputPhone.current.value, inputPassword.current.value).then((r) => {
      if (r.response) {

        Swal.fire({
          title: 'Başarıyla Kayıt Olundu',
          text: "Giriş yaparak devam edebilirsin ",
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        // MyHelper().SetLogin(r.userID, r.name + " " + r.surname);
        // MyHelper().SetLogin(r.userID, r.nameSurname);
        setForm("login")
        // ClickEvent();
      }
    }).catch((r) => {

    });
  }

  var email = useRef(null)
  var password = useRef(null)

  var history = useHistory();
  var inputName = useRef(null);
  var inputSurname = useRef(null);
  var inputMail = useRef(null);
  var inputPhone = useRef(null);
  var inputPassword = useRef(null);

  return (
    <>
      <div className="container mt-10">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className=" col-lg-6">
            <div className="tab tab-nav-boxed tab-nav-center tab-nav-underline">
              <ul className="nav nav-tabs text-uppercase" role="tablist">
                <li className="nav-item">
                  <a onClick={() => { setForm("login") }} className={"nav-link " + (form == "login" ? "active" : "")}>Giriş Yap</a>
                </li>
                <li className="nav-item">
                  <a onClick={() => { setForm("register") }} className={"nav-link " + (form == "register" ? "active" : "")}>Kayıt Ol</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className={"tab-pane" + (form == "login" ? "active" : "")} id="sign-in">
                  <div className="form-group  mb-5">
                    <label>Email *</label>
                    <input
                      ref={email}
                      type="text"
                      className="form-control"
                      name="username"
                      id="username"
                      required />
                  </div>
                  <div className="form-group  mb-5">
                    <label >Şifre *</label>
                    <input
                      ref={password}
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      required />
                  </div>
                  <div className="form-checkbox d-flex align-items-center justify-content-between">
                    <input type="checkbox" className="custom-checkbox" id="remember" name="remember" required />
                    <label className="mb-5" htmlFor="remember">Beni Hatırla  </label>
                    <a href="#">Şifre mi unuttum?</a>
                  </div>
                  <a onClick={ClickLogin} className="btn btn-primary  mb-10">Giriş Yap</a>
                </div>
                <div className={"tab-pane" + (form == "register" ? "active" : "")} id="sign-up">
                  <div className="form-group mb-5">
                    <label>İsim *</label>
                    <input ref={inputName} type="text" className="form-control" name="email_1" id="email_1" required />
                  </div>
                  <div className="form-group mb-5">
                    <label>Soyisim *</label>
                    <input ref={inputSurname} type="text" className="form-control" name="password_1" id="password_1" required />
                  </div>
                  <div className="form-group mb-5">
                    <label>Email *</label>
                    <input ref={inputMail} type="mail" className="form-control" name="email_1" id="email_1" required />
                  </div>
                  <div className="form-group mb-5">
                    <label>Telefon *</label>
                    <input ref={inputPhone} type="text" className="form-control" name="email_1" id="email_1" required />
                  </div>
                  <div className="form-group mb-5">
                    <label>Şifre *</label>
                    <input ref={inputPassword} type="password" className="form-control" name="email_1" id="email_1" required />
                  </div>

                  <div className="form-checkbox d-flex align-items-center justify-content-between mb-5">
                    <input type="checkbox" className="custom-checkbox" id="agree" name="agree" required />
                    <label htmlFor="agree" className="font-size-md">Kişisel verilerimin işlenmesine yönelik  <NavLink to="/privacy-policy" className="text-primary">aydınlatma metnini</NavLink> okudum ve anladım.</label>
                  </div>
                  <a onClick={() => ClickRegister()} className="btn btn-primary mb-6">Kayıt Ol</a>
                </div>
              </div>
              {/* <p className="text-center">Sosyal Meda giriş </p>
  <div className="social-icons social-icon-border-color d-flex justify-content-center">
    <a href="#" className="social-icon social-facebook w-icon-facebook" />
    <a href="#" className="social-icon social-twitter w-icon-twitter" />
    <a href="#" className="social-icon social-google fab fa-google" />
  </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Authentication
