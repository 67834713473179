import React, { useEffect } from "react";
import { renderToString } from 'react-dom/server'
export default class WrappedFrame extends React.Component {
    state = { contentHeight: 100 };
  
    handleResize = () => {
      const { body, documentElement } = this.container.contentWindow.document;
      const contentHeight = Math.max(
        body.clientHeight,
        body.offsetHeight,
        body.scrollHeight,
        documentElement.clientHeight,
        documentElement.offsetHeight,
        documentElement.scrollHeight
      );
      if (contentHeight !== this.state.contentHeight) this.setState({ contentHeight });
    };
  
    onLoad = () => {
      window.addEventListener("message", this.handler)
      this.container.contentWindow.addEventListener('resize', this.handleResize);
      this.handleResize();
  
      const btns = this.container.contentWindow.document.querySelectorAll('button');
      const btns2 = this.container.contentWindow.document.querySelector('[data-typeee="1"]');
      var a = this.container.contentWindow.document.querySelectorAll('[data-typeee]');

      if( this.props.content != undefined)
      this.props.content(this.container.contentWindow.document);
      for (var i in a) if (a.hasOwnProperty(i)) {
       
          if(a[i].getAttribute('data-typeee') == "btn")
          {
            var deneme = this;
            a[i].addEventListener('click', function (event) {
              deneme.props.onClick(event.currentTarget)
          });
          }
      }
  
  
      btns.forEach(btn => {
        btn.addEventListener('click', event => {
          this.props.onClick(event.currentTarget)
        });
  
      });
    }
  
    componentWillUnmount() {
      this.container.contentWindow.removeEventListener('resize', this.handleResize);
    }
  
    render() {
      const { contentHeight } = this.state;
      return (
        <div id="furkantime">
            
          <iframe
            frameBorder="0"
            onLoad={this.onLoad}
  
            ref={(container) => { this.container = container; }}
            scrolling="no"
            src="your.source"
            style={{ width: '100%', height: `${contentHeight}px` }}
            title="Some Content"
            srcdoc={renderToString(this.props.children)}
          />
        </div>
  
      );
    }
  }