import DBAuth from "./controller/DBAuth";
import DBBlog from "./controller/DBBlog";
import DBCategory from "./controller/DBCategory";
import DBGroup from "./controller/DBGroup";
import DBMedia from "./controller/DBMedia";
import DBOrder from "./controller/DBOrder";
import DBParamaters from "./controller/DBParamaters";
import DBProduct from "./controller/DBProduct";
import DBUsers from "./controller/DBUsers";

export default function DataManager()
{
   
    return {
        Users : DBUsers(),
        Auth : DBAuth(),
        Order : DBOrder(),
        Parematers : DBParamaters(),
        Product : DBProduct(),
        Media : DBMedia(),
        Blog : DBBlog(),
        Category : DBCategory(),
        Groups : DBGroup(),
    }
}