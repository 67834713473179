import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import WrappedFrame from "../../Components/WrappedFrame";
import AccountBody from "./AccountBody";

export default function Orders() {
    var history = useHistory();
    function OnClick(item) {

        if (item.id == "btn_orders") {
            history.push("/account/orders");
        }
        else if (item.id == "btn_address") {
            history.push("/account/address");
        }
        else if (item.id == "btn_address") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_account_information") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_like") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_logout") {
            history.push("/account/ticket");
        }else if (item.id == "btn_order_detail") {
            history.push("/account/orders/"+item.dataset.product);
        }
        else {
            alert(item.id)
        }
    }

    const [orders, setOrders] = useState([]);
    useEffect(() => {
        DataManager().Order.OrderUserList().then(r => {
            setOrders(r);
        })

    }, [])
    return <>
        <WrappedFrame onClick={OnClick} >
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />
         <AccountBody activeTab="order">
                <div className="tab-content mb-6">

                    <div className="tab-pane mb-4 active in" id="account-orders">
                        <div className="icon-box icon-box-side icon-box-light">
                            <span className="icon-box-icon icon-orders">
                                <i className="w-icon-orders" />
                            </span>
                            <div className="icon-box-content">
                                <h4 className="icon-box-title text-capitalize ls-normal mb-0">Siparişlerim</h4>
                            </div>
                        </div>
                        <table className="shop-table account-orders-table mb-6">
                            <thead>
                                <tr>
                                    <th className="order-id">ID</th>
                                    <th className="order-date">Oluşturma Tarihi</th>
                                    <th className="order-status">Sipariş Durumu</th>
                                    <th className="order-total">Tutar</th>
                                    <th className="order-actions">İşlem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders.map(item=>{
                                        return  <tr>
                                        <td className="order-id">{item.ek_order_id}</td>
                                        <td className="order-date">{item.ek_create_date}</td>
                                        <td className="order-status">
                                            {
                                                item.ek_order_status == 0 ? "Hazırlanıyor" : "Onay Bekliyor"
                                            }
                                        </td>
                                        <td className="order-total">
                                            <span className="order-price">{item.ek_price}₺</span>
                                        </td>
                                        <td className="order-action">
                                            <div data-typeee={"btn"} data-product={item.ek_order_id} id="btn_order_detail" className="btn btn-outline btn-default btn-block btn-sm btn-rounded">Görüntüle</div>
                                        </td>
                                    </tr>
                                    })
                                }
                           
                            </tbody>
                        </table>
                     
                    </div>

                </div>

            </AccountBody>


        </WrappedFrame>

    </>
}