
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import WrappedFrame from "../../Components/WrappedFrame";
import AccountBody from "./AccountBody";

export default function AccountInformation() {
    var {id} = useParams();
    var history = useHistory();
    function Logout() {
        Swal.fire({
          title: 'Çıkış yapmak istediğinize eminmisiniz?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Çıkış Yap',
          denyButtonText: 'Vazgeç',
        }).then((result) => {
          if (result.isConfirmed) {
            MyHelper().LogOut();
            history.push("/")
          } else if (result.isDenied) {
    
          }
        })
      }
    
    
    function OnClick(item) {

        if (item.id == "btn_orders") {
            history.push("/account/orders");
        }
        else if (item.id == "btn_address") {
            history.push("/account/address");
        }
        else if (item.id == "btn_address") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_account_information") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_like") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_logout") {
            Logout();
        }
        else if (item.id == "btn_address_save") {
            var inputUsernames = content.getElementsByTagName('input');
            var inputList = [];
            for (var i = 0, l = inputUsernames.length; i < l; i++) {
                inputList[inputUsernames[i].id] = inputUsernames[i].value;
            }
            alert(JSON.stringify(inputList));
           // DataManager().Users.UpdateProfile();
        }
        else {
            alert(item.id)
        }
    }

    const [address, setAddress] = useState([]);
    const [profile, setProfile] = useState([]);
    const [content, setContent] = useState([]);
    useEffect(() => {

     

    }, [])

    function SetContent(c) {
        setContent(c);

        DataManager().Users.GetProfile().then(r=>{
            var inputUsernames = c.getElementsByTagName('input');
            var inputList = [];
            for (var i = 0, l = inputUsernames.length; i < l; i++) {
                inputList[inputUsernames[i].id] = inputUsernames[i];

            }
           
          
            inputList["ek_user_name"].value = r["ek_user_name"];
            inputList["ek_user_surname"].value = r["ek_user_surname"];
            inputList["ek_phone"].value = r["ek_phone"];
            inputList["ek_mail"].value = r["ek_mail"];
           
        })
      
    }

    return <>
        <WrappedFrame content={SetContent} onClick={OnClick} >
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />
          <AccountBody>

                <div className="tab-content mb-6">
                    <div className="tab-pane active in" id="account-details">
                        <div className="icon-box icon-box-side icon-box-light">
                            <span className="icon-box-icon icon-account mr-2">
                                <i className="w-icon-user" />
                            </span>
                            <div className="icon-box-content">
                                <h4 className="icon-box-title mb-0 ls-normal">Hesap Bilgilerim</h4>
                            </div>
                        </div>
                        <div className="form account-details-form" >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="firstname">Adres Başlığı *</label>
                                        <input type="text" id="ek_user_name" name="firstname" placeholder="John" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Teslimat Yapılacak Kişinin Adı *</label>
                                        <input type="text" id="ek_user_surname" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Telefon</label>
                                        <input type="text" id="ek_phone" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div> <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Telefon</label>
                                        <input type="text" id="ek_mail" name="lastname" placeholder="Doe" className="form-control form-control-md" />
                                    </div>
                                </div>
                              
                                
                            </div>
                             <button id="btn_address_save" type="submit" className="btn btn-dark btn-rounded btn-sm mb-4">Kaydet</button>
                        </div>
                    </div>

                </div>
            </AccountBody>


        </WrappedFrame>

    </>
}