import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import WrappedFrame from "../../Components/WrappedFrame";
import AccountBody from "./AccountBody";

export default function Address() {
    var history = useHistory();
    function OnClick(item) {

        if (item.id == "btn_orders") {
            history.push("/account/orders");
        }
        else if (item.id == "btn_address") {
            history.push("/account/address");
        }
        else if (item.id == "btn_address") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_account_information") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_like") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_logout") {
            history.push("/account/ticket");
        } else if (item.id == "btn_order_detail") {
            history.push("/account/orders/" + item.dataset.product);
        }
        else if (item.id == "btn_address_update") {
            history.push("/account/address/" + item.dataset.address);
        }
        else if (item.id == "address_insert") {
            history.push("/account/address/insert" );
        }
        else {
            alert(item.id)
        }
    }

    const [address, setAddress] = useState([]);
    useEffect(() => {
        DataManager().Users.GetAllAddress().then(r => {
            setAddress(r);

        })

    }, [])
    return <>
        <WrappedFrame onClick={OnClick} >
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />
           <AccountBody activeTab="address">

                <div className="tab-content mb-6">
                    <div className="tab-pane active in" id="account-addresses">

                        <div class="d-flex justify-content-between">
                            <div>
                                <div className="icon-box icon-box-side icon-box-light">
                                    <span className="icon-box-icon icon-map-marker">
                                        <i className="w-icon-map-marker" />
                                    </span>
                                    <div className="icon-box-content">
                                        <h4 className="icon-box-title mb-0 ls-normal">Adreselerim</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button id={"address_insert"} className="btn btn-light-primary btn-cart">Adres Ekle</button>
                            </div>
                        </div>


                        <p>Kargolarınızı teslim alabileceğiniz adreslerin listesi</p>

                        <div className="row">

                            {
                                address.map(item => {
                                    return <div className="col-sm-6 mb-6">
                                        <div className="ecommerce-address billing-address pr-lg-8">
                                            <h4 className="title title-underline ls-25 font-weight-bold">{item.ek_tittle}</h4>
                                            <address className="mb-4">
                                                <table className="address-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Name:</th>
                                                            <td>{item.ek_owner_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Address:</th>
                                                            <td>{item.ek_open_address}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>City:</th>
                                                            <td>{item.ek_city}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Country:</th>
                                                            <td>{item.ek_country}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone:</th>
                                                            <td>{item.ek_phone}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </address>
                                            <div data-typeee="btn" id="btn_address_update" data-address={item.ek_address_id} href="#" className="btn btn-link btn-underline btn-icon-right text-primary">Adresi Güncelle<i className="w-icon-long-arrow-right" /></div>
                                        </div>
                                    </div>

                                })
                            }

                        </div>
                    </div>
                </div>
            </AccountBody>


        </WrappedFrame>

    </>
}