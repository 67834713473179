import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment'

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../../Ekmekk/global-data/selectors";
import { parameterState, searchState, categoryState } from "../../../../../Ekmekk/global-data/atom";

import DataManager from "../../../../../Ekmekk/database/DataManager";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import { StickyContainer } from "react-sticky";
import Sticky from "react-sticky-el";

function ShopPage() {
  const parameterList = useRecoilValue(allParametersList);

  const [entryDate, setEntryDate] = useState(new Date());
  const [exitDate, setExitDate] = useState(moment(entryDate).add(1, 'days'));


  const [searchDate, setSearchDate] = useRecoilState(searchState)

  const [topPage, setTopPage] = useState(1);
  const [firstAllItemCount, setFirstAllItemCount] = useState(0)
  const [maxItemPerPage, setMaxItemPerPage] = useState(9)
  const [pageIndex, setPageIndex] = useState(0)

  const [productList, setProductList] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [categoryList, setCategoryList] = useRecoilState(categoryState)

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState("Tümü");

  const { id } = useParams();
  const [mainProductList, setMainProductList] = useState(0);
  const [filterProductList, setFilterProductList] = useState(0);



  let minPrice = useRef()
  let maxPrice = useRef()

  var history = useHistory();

  const handleDateChange = (date, entry) => {
    entry ? setEntryDate(date) : setExitDate(date)
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    }
  }

  useEffect(() => {
    DataManager().Product.GetAllProduct(searchDate == {} ? "" : DateFormatter(entryDate), searchDate == {} ? "" : DateFormatter(exitDate)).then((r) => {


      setMainProductList(r);

      if (id == undefined) {
        setFilterProductList(r)
      }
      else {
        var filterArray = [...r];
        filterArray = filterArray.filter((item) => { return item.ek_category_id == id })
        setFilterProductList(filterArray)
      }





      setFirstAllItemCount(r.length);

      setTopPage((Math.ceil(r.length / maxItemPerPage)))
    })


    DataManager().Category.GetAllCategory().then(r => {
      setCategoryList(r);
    })

  }, [])


  function groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const [themeColor, setThemeColor] = useState("")

  const DateFormatter = (date) => {
    let data = moment(date).format('L').split('/')
    return data[2] + "-" + data[0] + "-" + data[1]
  }

  useEffect(() => {
    setThemeColor(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])

  useEffect(() => {
    DataManager().Product.GetAllProduct(searchDate == {} ? "" : DateFormatter(entryDate), searchDate == {} ? "" : DateFormatter(exitDate)).then((r) => {


      setMainProductList(r);

      if (id == undefined) {
        setFilterProductList(r)
      }
      else {
        var filterArray = [...r];
        filterArray = filterArray.filter((item) => { return item.ek_category_id == id })
        setFilterProductList(filterArray)
      }





      setFirstAllItemCount(r.length);

      setTopPage((Math.ceil(r.length / maxItemPerPage)))
    })


    DataManager().Category.GetAllCategory().then(r => {
      setCategoryList(r);
    })

  }, [id])




  function Filter(category) {

    setPageIndex(0);

    if (category == 0) {

      setFilterProductList(mainProductList)
    }
    else {
      setFilterProductList(mainProductList.filter(item => { return item.ek_category_id == category.ek_id }))


    }

  }

  function MobileFilter(category) {
    if (category == 0) {
      setSelectedCategory(0);
      setListProduct([...productList]);
    }
    else {
      setSelectedCategory(category);

      setListProduct(productList.filter(function (el) {
        return el.categoryID == category
      }));
    }

  }

  function groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  function UpdatePage(i) {

    // console.log(Math.round(productList.length))
    var oldIndex = pageIndex;

    console.log(oldIndex, i);
    if (oldIndex + parseInt(i) >= 0 && oldIndex + parseInt(i) <= Math.ceil(productList.length)) {
      setPageIndex(oldIndex + parseInt(i));
    }
    else if (oldIndex + parseInt(i) < 0) {
      setPageIndex(0);
    }

  }

  function FirstLastPage(i) {

    // console.log(Math.floor(productList.length / maxItemPerPage))
    if (i == 0) {
      setPageIndex(0);
    }
    else if (Math.floor(productList.length / maxItemPerPage) == 0) {
      setPageIndex(0);
    }
    else {
      setPageIndex((Math.floor(productList.length / maxItemPerPage)) * maxItemPerPage);
    }
  }

  function SortProduct(i) {
    if (listProduct != undefined) {

      var dizi = listProduct;

      if (i == 0) {

      }
      else if (i == 1) {
        if (dizi[i].price != null)
          dizi.sort(GetSortOrder2("price")); //Pass the attribute to be sorted on  
        else
          dizi.sort(GetSortOrder2("servicesPrice")); //Pass the attribute to be sorted on  

        setListProduct(dizi);
      }
      else {

        if (dizi[i].price != null)
          dizi.sort(GetSortOrder("price")); //Pass the attribute to be sorted on  
        else
          dizi.sort(GetSortOrder("servicesPrice")); //Pass the attribute to be sorted on  

        setListProduct(dizi);
      }
    }
  }

  function GetSortOrder(prop) {
    return function (a, b) {
      if (parseInt(a[prop]) < parseInt(b[prop])) {
        return 1;
      } else if (parseInt(a[prop]) > parseInt(b[prop])) {
        return -1;
      }
      return 0;
    }
  }

  function GetSortOrder2(prop) {
    return function (a, b) {
      if (parseInt(a[prop]) > parseInt(b[prop])) {
        return 1;
      } else if (parseInt(a[prop]) < parseInt(b[prop])) {
        return -1;
      }
      return 0;
    }
  }

  function ChangeSearchText(e) {
    var filteredList = productList.filter(item => item.ek_product_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
    setListProduct(filteredList);
    //listProduct
  }

  const selectSorting = (value) => {
    //alert(value)
    setSelectedOrder(value)
    SortProduct(value)
  }

  const priceFilter = (min, max) => {
    let dummyProductList = [...mainProductList].filter(el => el.price > parseInt(min) && el.price < parseInt(max))
    setFilterProductList(dummyProductList)

  }

  return (
    <>

      <main className="main">
        {/* Start of Breadcrumb */}
        <nav className="breadcrumb-nav">
          <div className="container">

          </div>
        </nav>
        {/* End of Breadcrumb */}
        {/* Start of Page Content */}
        <div className="page-content">
          <div className="container">
            {/* Start of Shop Banner */}

            {/* End of Shop Banner */}
            <div className="shop-default-brands mb-5">
              <div className="row brands-carousel owl-carousel owl-theme cols-xl-7 cols-lg-6 cols-md-4 cols-sm-3 cols-2" data-owl-options="{
                                'items': 7,
                                'nav': false,
                                'dots': true,
                                'margin': 20,
                                'loop': true,
                                'autoPlay': 'true',
                                'responsive': {
                                    '0': {
                                        'items': 2
                                    },
                                    '576': {
                                        'items': 3
                                    },
                                    '768': {
                                        'items': 4
                                    },
                                    '992': {
                                        'items': 6
                                    },
                                    '1200': {
                                        'items': 7
                                    }
                                }
                            }
                        ">
                <figure>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={160} height={90} />
                </figure>
                <figure>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={160} height={90} />
                </figure>
                <figure>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={160} height={90} />
                </figure>
                <figure>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={160} height={90} />
                </figure>
                <figure>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAiCAMAAABSpLb8AAAAZlBMVEX///8AAAD4+PgeHh7v7+/Pz8/y8vLq6ur19fV/f39OTk6pqamKiop1dXXT09PW1ta9vb2xsbHe3t7Hx8dsbGxAQEBgYGBWVlaZmZmioqIXFxdISEgxMTERERGRkZFnZ2clJSU5OTknJ01nAAAB90lEQVRIie2W25KCMAyGWxAQKFBOirrK+v4vudAmobSKsu7NzpALp4G/+WiStjK2mWuhLPPXqkiW1V8RvfEn5YO10QupGFUHby1hnMUD9KJW+TwbxqUenlXIiLu2IyznFyNk+mWImiff7ZvcGsTl6FzBOS1xd+jUFPFoyWqHaXNvWuirclU477DElegIDNg7uofVn7gefCjUikiLXMwQv0G8wtUdFrkV5LVTi+1EinnOOiFZ2GTK9JNYGSs6caL13kRNOfMTMN0uS9wU5quld/Yn+9gdytvrcWarLsOLeBzEGF2XP3zOhbZsVY+Wbq58rd5TgqmTTUtsbrTMzXNIqX72DWF6eaK+KW2uRxkuU0wWz9dxC93JUj8KMcqdsQDHsc3N8U0zFWnYcx9waSUXoz2FzaV9lhmFSX+XZ9AnEOXYJFTE2uYyfNP3xxjH0TpuwGBmO1/KZLAHTa50VWJlXwXT1ilU7a5WQGi4GdcFj1t/LZc6SB3HrKpp0aKo96iecYfqB9R3aR6oc241l+3gLmr0C+IeDbXFfXA+r+fSAfQVTVOm73jMpfV2H3BpP6jmvYMjl7jU1XjpvMs9m1xWwVE13i6Q6Nlt4nLlPM1vc73RbN9TyurCp/vtiXzI9PDv4ppa8xf071j46s+Vst1bqs0222yzf2U/kV8VA3FS8IsAAAAASUVORK5CYII=" alt="Brand" width={160} height={90} />
                </figure>

              </div>
            </div>

            <div className="shop-content row gutter-lg mb-10">
              {/* Start of Sidebar, Shop Sidebar */}
              <aside className="sidebar shop-sidebar sticky-sidebar-wrapper sidebar-fixed">
                {/* Start of Sidebar Overlay */}
                <div className="sidebar-overlay" />
                <a className="sidebar-close"  ><i className="close-icon" /></a>
                {/* Start of Sidebar Content */}
                <div className="sidebar-content scrollable">
                  {/* Start of Sticky Sidebar */}
                  <div className="sticky-sidebar">
                    <div className="filter-actions">
                      <label>Filter :</label>
                      <a onClick={() => { priceFilter(0, 99999999999); Filter(0) }} className="btn btn-dark btn-link filter-clean">Temizle</a>
                    </div>
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title"><label>Fiyat</label></h3>
                      <div className="widget-body">
                        <ul className="filter-items search-ul">
                          <li><a onClick={() => priceFilter(0, 100)} >₺0.00 - ₺100.00</a></li>
                          <li><a onClick={() => priceFilter(100, 200)} >₺100.00 - ₺200.00</a></li>
                          <li><a onClick={() => priceFilter(200, 300)} >₺200.00 - ₺300.00</a></li>
                          <li><a onClick={() => priceFilter(300, 500)} >₺300.00 - ₺500.00</a></li>
                          <li><a onClick={() => priceFilter(500, 9999999999999999)} >₺500.00+</a></li>
                        </ul>
                        <form className="price-range">
                          <input type="number" name="min_price" ref={minPrice} className="min_price text-center" placeholder="₺min" /><span className="delimiter">-</span>
                          <input type="number" name="max_price" ref={maxPrice} className="max_price text-center" placeholder="₺max" /><a onClick={() => priceFilter(minPrice.current.value, maxPrice.current.value)} className="btn btn-primary btn-rounded">Ara</a>
                        </form>
                      </div>
                    </div>
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title"><label>Tüm Kategoriler</label></h3>
                      <ul className="widget-body filter-items search-ul" style={{ overflowY: "scroll", maxHeight: "400px" }}>



                        {
                          categoryList && categoryList.map(item => {
                            return (
                              <li><a style={{ cursor: 'pointer' }} onClick={() => { Filter(item) }} >{item.ek_category_name}</a></li>
                            )
                          })
                        }

                      </ul>
                    </div>
                    {/* End of Collapsible Widget */}
                    {/* Start of Collapsible Widget */}


                    {/* <div className="widget widget-collapsible">
                      <h3 className="widget-title"><label>Size</label></h3>
                      <ul className="widget-body filter-items item-check mt-1">
                        <li><a  >Extra Large</a></li>
                        <li><a  >Large</a></li>
                        <li><a  >Medium</a></li>
                        <li><a  >Small</a></li>
                      </ul>
                    </div>
         
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title"><label>Brand</label></h3>
                      <ul className="widget-body filter-items item-check mt-1">
                        <li><a  >Elegant Auto Group</a></li>
                        <li><a  >Green Grass</a></li>
                        <li><a  >Node Js</a></li>
                        <li><a  >NS8</a></li>
                        <li><a  >Red</a></li>
                        <li><a  >Skysuite Tech</a></li>
                        <li><a  >Sterling</a></li>
                      </ul>
                    </div>
   
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title"><label>Color</label></h3>
                      <ul className="widget-body filter-items item-check mt-1">
                        <li><a  >Black</a></li>
                        <li><a  >Blue</a></li>
                        <li><a  >Brown</a></li>
                        <li><a  >Green</a></li>
                        <li><a  >Grey</a></li>
                        <li><a  >Orange</a></li>
                        <li><a  >Yellow</a></li>
                      </ul>
                    </div> */}
                    {/* End of Collapsible Widget */}
                  </div>
                  {/* End of Sidebar Content */}
                </div>
                {/* End of Sidebar Content */}
              </aside>
              {/* End of Shop Sidebar */}
              {/* Start of Shop Main Content */}
              <div className="main-content">
                <nav className="toolbox sticky-toolbox sticky-content fix-top">
                  <div className="toolbox-left">
                    <a className="btn btn-primary btn-outline btn-rounded left-sidebar-toggle 
                                        btn-icon-left d-block d-lg-none"><i className="w-icon-category" /><span>Filters</span></a>
                    <div className="toolbox-item toolbox-sort select-box text-dark">

                      <select onChange={(e) => selectSorting(e.target.value)} name="orderby" className="form-control">
                        <option value="0" selected="selected">Filtre Yok</option>
                        <option value="1">Fiyata Göre Artan</option>
                        <option value="2">Fiyata Göre Azalan</option>
                        {/* <option value="date">Sort by latest</option>
                        <option value="price-low">Sort by pric: low to high</option>
                        <option value="price-high">Sort by price: high to low</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="toolbox-right">
                    <div className="toolbox-item toolbox-show select-box">
                      {/* <select name="count" className="form-control">
                        <option value={9}>Show 9</option>
                        <option value={12} selected="selected">Show 12</option>
                        <option value={24}>Show 24</option>
                        <option value={36}>Show 36</option>
                      </select> 
                    </div>
                    <div className="toolbox-item toolbox-layout">
                      <a href="shop-banner-sidebar.html" className="icon-mode-grid btn-layout active">
                        <i className="w-icon-grid" />
                      </a>
                      <a href="shop-list.html" className="icon-mode-list btn-layout">
                        <i className="w-icon-list" />
                      </a>
                      */}
                    </div>
                  </div>
                </nav>

                <div className="product-wrapper row cols-md-3 cols-sm-2 cols-1" style={{ overflowY: 'scroll' }}>

                  {
                    filterProductList && filterProductList.map((product, index) => {
                      if (index >= pageIndex && index < pageIndex + maxItemPerPage) {
                        return (
                          <div style={{ cursor: 'pointer' }} onClick={() => history.push('/product/' + product.productID)} className="product-wrap">
                            <div className="product text-center">
                              <figure style={{ maxHeight: '350px' }} className="product-media">
                                <a >
                                  <div className="box">
                                    <img src={product.ek_image_path} style={{ objectFit: "contain", height: "250px" }} alt="Product" />
                                  </div>

                                </a>
                                <div className="product-action-horizontal">
                                  <a className="btn-product-icon btn-cart w-icon-cart" title="Add to cart" />
                                  <a className="btn-product-icon btn-wishlist w-icon-heart" title="Wishlist" />
                                  <a className="btn-product-icon btn-compare w-icon-compare" title="Compare" />
                                  <a className="btn-product-icon btn-quickview w-icon-search" title="Quick View" />
                                </div>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a >{product.ek_short_description}</a>
                                </div>
                                <h3 className="product-name">
                                  <a>{product.ek_product_name}</a>
                                </h3>
                                <div className="ratings-container">
                                  <div className="ratings-full">
                                    <span className="ratings" style={{ width: '100%' }} />
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  <a className="rating-reviews">(3 reviews)</a>
                                </div>
                                <div className="product-pa-wrapper">
                                  <div className="product-price">
                                    {product.price} ₺
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  }


                </div>
                <div className="toolbox toolbox-pagination justify-content-between">

                  <div className="product">

                    <div className="row mt-2 text-center">

                      <div className="text-center">
                        <span className="text-muted">Toplam {topPage} sayfa içerisinde {Math.floor(pageIndex / maxItemPerPage) + pageIndex % maxItemPerPage + 1}. sayfayı görmektesiniz. </span>
                      </div>

                    </div>

                    <div className="row mt-2 text-center">

                      {/*begin::Pagination*/}
                      {/* <div className="d-flex justify-content-between align-items-center flex-wrap text-center"> */}
                      <div className="text-center">
                        <div className="mr-3">

                          <a onClick={() => FirstLastPage(0)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-double-left.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fillRule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
                                <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
                              </g>
                            </svg>{/*end::Svg Icon*/}</span>

                          </a>
                          <a onClick={() => UpdatePage(-maxItemPerPage)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-left.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-12.000003, -11.999999) " />
                              </g>
                            </svg>{/*end::Svg Icon*/}</span>

                          </a>
                          <a onClick={() => UpdatePage(-maxItemPerPage)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{pageIndex == 0 ? ".." : Math.floor(pageIndex / maxItemPerPage) + (pageIndex % maxItemPerPage)}</a>
                          <a onClick={() => UpdatePage(0)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{Math.floor(pageIndex / maxItemPerPage) + (pageIndex % maxItemPerPage) + 1}</a>
                          <a onClick={() => UpdatePage(maxItemPerPage)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{Math.floor(pageIndex / maxItemPerPage) + (pageIndex % maxItemPerPage) + 2}</a>
                          <a onClick={() => UpdatePage(maxItemPerPage)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-right.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) " />
                              </g>
                            </svg>{/*end::Svg Icon*/}</span>

                          </a>
                          <a onClick={() => FirstLastPage(1)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-double-right.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero" />
                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
                              </g>
                            </svg>{/*end::Svg Icon*/}</span>
                          </a>

                        </div>
                      </div>
                      {/*end:: Pagination*/}
                    </div>

                    <div className="row mt-2 text-center">

                      <div className="text-center">
                        <span className="text-muted">Her sayfada </span>

                        <select className="text-center  form-control-sm  font-weight-bold mr-4 ml-4 border-0 " style={{ width: '75px', color: "" + themeColor + "" }} onChange={(e) => { setMaxItemPerPage(parseInt(e.target.value)); setTopPage((Math.ceil(productList.length / e.target.value))); setPageIndex(0) }}>
                          <option value={9}>9</option>
                          <option value={15}>15</option>
                          <option value={21}>21</option>
                          <option value={27}>27</option>
                          <option value={33}>33</option>
                        </select>
                        <span className="text-muted ml-4">ürün göster</span>
                      </div>

                    </div>

                  </div>
                </div>



              </div>
              {/* End of Shop Main Content */}
            </div>
            {/* End of Shop Content */}
          </div>
        </div>
        {/* End of Page Content */}
      </main>
    </>
  )
}

export default ShopPage
