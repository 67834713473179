import MyHelper from '../../controller/MyHelper';

const axios = require('axios');
export default function DBMedia() {
    function Upload(files) {
        return new Promise((ok, fail) => {
            var bodyFormData = new FormData();
            bodyFormData.append('command', 'media_upload');
            console.log(files);
            for (var i = 0; i < files.length; i++) {
                bodyFormData.append('files[]', files[i]);
            }


            

            axios({
                method: "post",
                url: MyHelper().URLwithService(),
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        })
    }
    function ListImages() {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'media_list',
            })
                .then(function (response) {
                    ok(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function DeleteImage(fileName) {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'media_delete',
                mediaFileName: fileName
            })
                .then(function (response) {
                    ok(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }


    return {
        Upload, ListImages, DeleteImage
    }
}