
import { useState } from "react";
import { useHistory } from "react-router-dom";

import Swal from 'sweetalert2'

import { useRef } from "react";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import { useEffect } from "react";
import DataManager from "../../../../../Ekmekk/database/DataManager";

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../../Ekmekk/global-data/selectors";

function BlogPage() {
  const parameterList = useRecoilValue(allParametersList);

  var history = useHistory();

  const [blogList, setBlogList] = useState();

  var history = useHistory();

  const [themeColor, setThemeColor] = useState("")

  useEffect(() => {

      setThemeColor(MyHelper().GetThemeColor(parameterList));
      console.log(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])


  useEffect(() => {
      DataManager().Blog.List().then((r) => {
          setBlogList(r);
          console.log(r)
      })

  }, [])
    return (
        <>
<main className="main">
  {/* Start of Page Header */}
  <div className="page-header">
    {/* <div className="container">
      <h1 className="page-title mb-0">Masonry 2</h1>
    </div> */}
  </div>
  {/* End of Page Header */}
  {/* Start of Breadcrumb */}
  <nav className="breadcrumb-nav mb-6">
    <div className="container">
      {/* <ul className="breadcrumb">
        <li><a href="demo1.html">Home</a></li>
        <li><a href="blog.html">Blog</a></li>
        <li>Masonry 2</li>
      </ul> */}
    </div>
  </nav>
  {/* End of Breadcrumb */}
  {/* Start of Page Content */}
  <div className="page-content mb-10">
    <div className="container">
      {/* <ul className="nav-filters filter-underline blog-filters mb-4">
        <li><a href="#" className="nav-filter active" data-filter="*">All Blog Posts <span>6</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".clothes">Clothes <span>1</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".entertainment">Entertainment <span>1</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".fashion">Fashion <span>2</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".lifestyle">Lifestyle <span>3</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".others">Others <span>2</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".shoes">Shoes <span>1</span></a></li>
        <li><a href="#" className="nav-filter" data-filter=".technology">Technology <span>1</span></a></li>
      </ul> */}
      

      {
        blogList && blogList.map(blog => {
          return (
            <article className="post post-list post-listing mb-md-10 mb-6 pb-2 overlay-zoom mb-4">
            <figure className="post-media br-sm">
              <a >
                <img src={blog.ek_image} alt="blog" style={{maxHeight: 930,maxWidth: 500}} width={930} height={500} />
              </a>
            </figure>
            <div className="post-details">
              <div className="post-cats text-primary">
                <a >{blog.ek_tag}</a>
              </div>
              <h4 className="post-title">
                <a >{blog.ek_tittle}</a>
              </h4>
              <div className="post-content">
                <p><div dangerouslySetInnerHTML={{ __html: blog.ek_text }} />…</p>
                <a  onClick={() => history.push('/blogdetail/'+blog.ek_blog_id)} className="btn btn-link btn-primary">(Devamını Oku)</a>
              </div>
              <div className="post-meta">
                by <a  className="post-author">Admin</a>
                - <a  className="post-date">{blog.ek_create_date.toString().substring(0,10)}</a>
              </div>
            </div>
          </article>
          )
        })
      }

    </div>
  </div>
  {/* End of Page Content */}
</main>

   
        </>
    )
}

export default BlogPage
