import axios from "axios";
import MyHelper from "../../controller/MyHelper";

export default function DBUsers() {
    function GetProfile() {
        return new Promise((ok, fail) => {
        axios.post(MyHelper().URLwithService(), {
            command: 'user_profile_get',
            userID: MyHelper().GetUserID()
        })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        });
    }
    function UpdateProfile(inputName, inputSurname, inputMail, inputPhone) {
        return new Promise((ok, fail) => {
            // alert(JSON.stringify({
            //     command: 'user_profile_update',
            //     userID: MyHelper().GetUserID(),
            //     inputName:inputName,
            //     inputSurname:inputSurname,
            //     inputMail:inputMail,
            //     inputPhone:inputPhone,
            //  } ));
        axios.post(MyHelper().URLwithService(), {
            command: 'user_profile_update',
            userID: MyHelper().GetUserID(),
            inputName:inputName,
            inputSurname:inputSurname,
            inputMail:inputMail,
            inputPhone:inputPhone,
        })
       
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        });
    }
    function GetOrders() {

    }
    function GetTickets() {

    }

    function GetAllAddress() {

        return new Promise((ok, fail) => {
            if (MyHelper().GetUserID() == undefined) {
                ok([])
            }
            else {
                axios.post(MyHelper().URLwithService(), {
                    command: 'address_list',
                    userID: MyHelper().GetUserID()
                })
                    .then(function (response) {
                        console.log(response)
                        ok(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

        })
    }
    function RemoveAddress(addressID) {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'address_remove',
                userID: MyHelper().GetUserID(),
                addressID: addressID,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function InsertAddress(tittle, ownerName, phone, openAddress, country, city, district, neighborhood) {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'address_insert',
                userID: MyHelper().GetUserID(),
                tittle: tittle,
                ownerName: ownerName,
                phone: phone,
                openAddress: openAddress,
                country: country,
                city: city,
                district: district,
                neighborhood: neighborhood,
            })
                .then(function (response) {
                    console.log(response);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function UpdateAddress(addressID, tittle, ownerName, phone, openAddress, country, city, district, neighborhood) {

        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'address_update',
                userID: MyHelper().GetUserID(),
                tittle: tittle,
                addressID: addressID,
                ownerName: ownerName,
                phone: phone,
                openAddress: openAddress,
                country: country,
                city: city,
                district: district,
                neighborhood: neighborhood,
            })
                .then(function (response) {
                    console.log(response);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    return {
        GetOrders, GetProfile, GetTickets, GetAllAddress, InsertAddress, RemoveAddress, UpdateAddress,UpdateProfile
    }
}