import { faPlus, faShoppingCart, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { renderToString } from 'react-dom/server'
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactDOM, { createPortal } from 'react-dom'
import BasketManager from "../../../../../Ekmekk/controller/BasketManager";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import Counter from "../../../../../Ekmekk/component/counter";
import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../../Ekmekk/global-data/selectors";
import { renderHeader } from "../../../../../Ekmekk/global-data/atom";
import { useRef } from "react";
import { Portal } from 'react-portal';

import WrappedFrame from "../../Components/WrappedFrame";

function ShoppingCard() {

  const parameterList = useRecoilValue(allParametersList);
  const [render, setRender] = useRecoilState(renderHeader)


  const [baskeetItems, setBasketItems] = useState();
  const [toplamTutar, setToplamTutar] = useState(0);

  var history = useHistory();

  const [themeColor, setThemeColor] = useState("")

  useEffect(() => {

    setThemeColor(MyHelper().GetThemeColor(parameterList));
    console.log(MyHelper().GetThemeColor(parameterList));
  }, [parameterList])

  useEffect(() => {
    setBasketItems(BasketManager().GetAllProductsNormal());
    setRender(BasketManager().GetAllProductsNormal())
    GetTotalPrice();

  }, [])

  function GetTotalPrice() {

    var dizi = BasketManager().GetAllProductsNormal()
    var tutar = 0;
    for (let i = 0; i < dizi.length; i++) {
      if (dizi[i].days != undefined) {
        dizi[i].count = dizi[i].days
      }
      tutar = tutar + (dizi[i].count * dizi[i].price);
    }
    setToplamTutar(tutar.toFixed(2));

  }

  function Test() {
  }
  function RemoveProduct(id) {


    baskeetItems.map((mainItem, index1) => {
      if (mainItem.ek_id == id) {
        baskeetItems.splice(index1, 1);
        setBasketItems([...baskeetItems])
        setRender([...baskeetItems])
        BasketManager().RemoveProduct(mainItem);
        global.basketRefresh();

      }
    })
  }

  useEffect(() => {
    BasketManager().SetProducts(baskeetItems);
    GetTotalPrice();
  }, [baskeetItems])
  function WrapperClickEvent(item) {
    if (item.id == "okey") {
      history.push("check-out");
    }
    else if (item.id == "counter_plus") {
      baskeetItems.map((mainItem, index1) => {
        if (mainItem.ek_id == item.dataset.product) {

          baskeetItems[index1].count += 1;

        }
      })


      setBasketItems([...baskeetItems])


    }
    else if (item.id == "counter_minus") {
      baskeetItems.map((mainItem, index1) => {
        if (mainItem.ek_id == item.dataset.product) {

          baskeetItems[index1].count += -1;

        }
      })


      setBasketItems([...baskeetItems])


    }
    else if (item.id == "product_remove") {

      RemoveProduct(item.dataset.product)
    }

  }
  return (
    <>
      <WrappedFrame onClick={WrapperClickEvent}>
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + "/thema/wolmart/assets/css/style.min.css"} />

        <div className="main cart">
          <div className="banner banner-newsletter">
            <div className="banner-content text-center">
              <h3 className="banner-title" onClick={() => {
                window.top.postMessage(
                  JSON.stringify({
                    error: false,
                    message: "Hello World"
                  }),
                  '*'
                );
              }}> Ücretsiz kargo </h3>
              <p>Yapacağınız 300 ₺ ve üzeri alışverişlerin tamamında kargo ücreti ödemeden satın alın</p>


            </div>
          </div>
          <nav className="breadcrumb-nav">
            <div className="container">
              <ul className="breadcrumb shop-breadcrumb bb-no">
                <li className="active"><a href="cart.html">Sepetim</a></li>
                <li><a href="checkout.html">Adres seçimi</a></li>
                <li><a href="order.html">Ödeme</a></li>
              </ul>
            </div>
          </nav>



          <div className="page-content">
            <div className="container">
              <div className="row gutter-lg mb-10">
                <div className="col-lg-8 pr-lg-4 mb-6">
                  <table className="shop-table cart-table">
                    <thead>
                      <tr>
                        <th className="product-name"><span>Ürün</span></th>
                        <th />
                        <th className="product-price"><span>Birim Fiyatı</span></th>
                        <th className="product-quantity"><span>Adet</span></th>
                        <th className="product-subtotal"><span>Ara Toplam</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        baskeetItems == undefined ? "" : baskeetItems.map(mainItem => {
                          return <tbody>
                            <tr>
                              <td className="product-thumbnail">
                                <div className="p-relative">
                                  <a href="product-default.html">
                                    <figure>
                                      <img src={mainItem.images.split(',')[0].split("$")[0]} alt="product" width={300} height={338} />
                                    </figure>
                                  </a>
                                  <button id="product_remove" data-product={mainItem.ek_id} type="submit" className="btn btn-close"><FontAwesomeIcon icon={faTimes} /></button>
                                </div>
                              </td>
                              <td className="product-name">
                                <a href="product-default.html">
                                  {mainItem.ek_product_name}
                                </a>
                              </td>
                              <td className="product-price"><span className="amount">
                                {mainItem.price.toFixed(2)} ₺
                              </span>
                              </td>
                              <td className="product-quantity">
                                <Counter productID={mainItem.ek_id} selectedItem={mainItem.count} />
                              </td>
                              <td className="product-subtotal">
                                <span className="amount">{(mainItem.price * mainItem.count).toFixed(2)} ₺</span>
                              </td>



                            </tr>

                          </tbody>

                        })
                      }


                    </tbody>
                  </table>
                  <div className="cart-action mb-6">
                    <button type="submit" className="btn btn-rounded btn-default btn-clear  mr-auto" name="clear_cart" value="Clear Cart">Sepeti Temizle</button>

                    <button type="submit" className="btn btn-rounded btn-update mr-2" name="update_cart" value="Update Cart">Alışverişe Devam Et </button>
                    <a href="#" className="btn btn-dark btn-rounded btn-icon-left btn-shopping"><i className="w-icon-long-arrow-left" />Ödeme</a>

                  </div>
                </div>
                <div className="col-lg-4 sticky-sidebar-wrapper">
                  <div className="pin-wrapper"><div className="sticky-sidebar">
                    <div className="cart-summary mb-4">
                      <h3 className="cart-title text-uppercase">Sepet Toplamı</h3>
                      <div className="cart-subtotal d-flex align-items-center justify-content-between">
                        <label className="ls-25">Ara Toplam</label>
                        <span>{toplamTutar} ₺</span>
                      </div>
                      <hr className="divider" />
                      <ul className="shipping-methods mb-2">
                        <li>
                          <label className="shipping-title text-dark font-weight-bold">Kargo</label>
                        </li>
                        <li>
                          <div className="custom-radio">
                            <input type="radio" id="free-shipping" className="custom-control-input" name="shipping" />
                            <label htmlFor="free-shipping" className="custom-control-label color-dark">Şehir içi ücretsiz teslimat (Ankara)</label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-radio">
                            <input type="radio" id="local-pickup" className="custom-control-input" name="shipping" />
                            <label htmlFor="local-pickup" className="custom-control-label color-dark">PTT ve MNG kargo teslimat</label>
                          </div>
                        </li>
                      </ul>
                      <hr className="divider" />
                      <table className="order-table">
                        <tbody>
                          <tr className="bb-no"><td style={{ fontSize: '1.5rem' }} className="product-name"><i className="w-icon-cart2 mr-1" />Tahmini Teslim Tarihi</td><td style={{ fontSize: '1.5rem' }} className="product-total">{parameterList.filter(item => item.ek_key == "businessCargoDay")[0].ek_value} İş Günü</td></tr>
                          <tr className="bb-no">
                            <td style={{ fontSize: '1.5rem' }} className="product-name">
                              <i className="w-icon-truck mr-1" />Kargo Ulaşım Bedeli
                            </td>
                            <td style={{ fontSize: '1.5rem' }} className="product-total">
                              {parseFloat(toplamTutar) > parseFloat(parameterList.filter(item => item.ek_key == "businessCargoMinimumLimit")[0].ek_value) ? "0.0 " : parseFloat(parameterList.filter(item => item.ek_key == "businessCargoPrice")[0].ek_value)} ₺
                            </td>
                          </tr>

                        </tbody>
                        <tfoot />
                      </table>

                      <hr className="divider mb-6" />
                      <div className="order-total d-flex justify-content-between align-items-center">
                        <label>Toplam</label>
                        <span className="ls-50">{parseFloat(toplamTutar) + (parseFloat(toplamTutar) > parseFloat(parameterList.filter(item => item.ek_key == "businessCargoMinimumLimit")[0].ek_value) ? 0 : parseFloat(parameterList.filter(item => item.ek_key == "businessCargoPrice")[0].ek_value))} ₺</span>
                      </div>
                      <button id={"okey"} className="btn btn-block btn-dark btn-icon-right btn-rounded  btn-checkout">
                        Ödeme<i className="w-icon-long-arrow-right" /></button>
                    </div>
                  </div></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </WrappedFrame>

    </>
  )

}









export default ShoppingCard
