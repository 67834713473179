export const creditCardList = [
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 404591,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 407814,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 413226,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 444676,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 444677,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 444678,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 447504,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 454671,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 454672,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 454673,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 454674,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 469884,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 476619,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 512440,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 513662,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 516932,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 523529,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 527682,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 528208,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 530905,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 531102,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 533154,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 534981,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 540130,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 540134,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 542374,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 546957,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 547287,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 549449,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 676123,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 676124,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 415514,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 415515,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 421030,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 440776,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 447505,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 451454,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 492094,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 492095,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 498852,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 499821,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 510056,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 521378,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 526289,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 526290,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 540435,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 543081,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 552879,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 588843,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 639001,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 676258,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 979210,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 979212,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 402940,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 409084,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 411724,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 411942,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 411943,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 411944,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 411979,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 415792,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 416757,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 428945,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 442671,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 459252,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 479908,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 479909,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 491005,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 493840,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 493841,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 493846,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 520017,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 540045,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 540046,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 542119,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 542798,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 542804,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 547244,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 552101,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 554548,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 589311,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 979209,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 402142,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 402458,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 402459,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 404315,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 406015,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 413528,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 427308,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 427707,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 438040,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 440247,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 440273,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 440293,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 440294,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 440295,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 447503,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 450918,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 455645,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 459026,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 469188,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 479227,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 489494,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 489495,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 489496,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 510138,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 510139,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 510221,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 512753,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 512803,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 516742,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 519780,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 524346,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 524839,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 524840,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 525314,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 527026,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 528825,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 528920,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 530853,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 531531,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 532581,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 534538,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 535217,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 542259,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 545124,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 545148,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 547985,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 549998,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 550449,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 552207,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 553090,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 606329,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 676406,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 413252,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 425669,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 432071,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 432072,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 435508,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 435509,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 474853,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 474854,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 512754,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 516840,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 516841,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 520932,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 521807,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 521942,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 524347,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 535280,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 552608,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 552609,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 553056,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 557113,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 557829,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 589004,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 979206,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 979207,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 979208,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 403836,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 411156,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 411157,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 411158,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 411159,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 421086,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 423833,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 433383,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 459068,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 459268,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 489401,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 494063,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 494064,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 516846,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 519753,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 521394,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 525404,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 527657,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 530866,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 547311,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 549208,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 589713,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 676832,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374421,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374422,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374424,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374425,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374426,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374427,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375622,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375623,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375624,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375625,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375626,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375627,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375628,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375629,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375631,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 377137,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 401738,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 403280,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 403666,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 404308,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 405051,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 405090,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 409219,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 410141,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 413836,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 420556,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 420557,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 426886,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 426887,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 426888,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 426889,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 427314,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 427315,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 428220,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 428221,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 432154,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 448472,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 461668,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 462274,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 467293,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 467294,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 467295,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 474151,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 479682,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 482489,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 482490,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 482491,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 486567,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 487074,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 487075,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 489455,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 489478,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 490175,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 492186,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 492187,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 492193,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 493845,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 514915,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 516943,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 516961,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 517040,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 517041,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 517042,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 517048,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 517049,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 520097,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 520922,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 520940,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 520988,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 521368,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 521824,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 521825,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 521832,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 522204,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 524659,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 526955,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 528939,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 528956,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 533169,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 534261,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 535488,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540036,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540037,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540118,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540226,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540227,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540669,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 540709,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 541865,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 542030,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 543738,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 544078,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 545102,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 546001,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 547302,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 548935,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 552095,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 553130,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 554796,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 554960,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 557023,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 557945,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 558699,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 589318,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 622403,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 670606,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 676255,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 676283,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 676651,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 676827,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 979236,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 418342,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 418343,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 418344,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 418345,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 441075,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 450803,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 454318,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 454358,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 454359,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 454360,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 479610,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 510152,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 540667,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 540668,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 543771,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 548237,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 552096,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 553058,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 589283,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 401622,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 404809,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 413382,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 414392,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 420342,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 420343,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 442106,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 446212,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 450634,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 455359,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 479612,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 479794,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 479795,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 490983,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 491205,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 491206,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 492128,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 492130,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 492131,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 494314,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 516888,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 525864,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 535435,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 535601,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 535602,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 540061,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 540062,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 540063,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 540122,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 540129,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 542117,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 545103,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 552645,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 552659,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 603797,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 639004,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 676166,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 92,
        "MemberName": "CITIBANK A.Ş.",
        "PrefixNo": 437897,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 92,
        "MemberName": "CITIBANK A.Ş.",
        "PrefixNo": 471509,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 92,
        "MemberName": "CITIBANK A.Ş.",
        "PrefixNo": 553493,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 92,
        "MemberName": "CITIBANK A.Ş.",
        "PrefixNo": 555087,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 96,
        "MemberName": "TURKISH BANK A.Ş.",
        "PrefixNo": 518599,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 96,
        "MemberName": "TURKISH BANK A.Ş.",
        "PrefixNo": 529939,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 96,
        "MemberName": "TURKISH BANK A.Ş.",
        "PrefixNo": 552098,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 96,
        "MemberName": "TURKISH BANK A.Ş.",
        "PrefixNo": 589288,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 96,
        "MemberName": "TURKISH BANK A.Ş.",
        "PrefixNo": 677522,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 400684,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 408579,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 408581,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 414070,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 420322,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 420323,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 420324,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 455571,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 474823,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 479632,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 479633,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 480296,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 490805,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 490806,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 490807,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 490808,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 510151,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 514140,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 515755,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 515895,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 517946,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 526973,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 526975,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 532443,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 535137,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 540024,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 540025,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 542029,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 542605,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 542965,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 542967,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 547765,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 548819,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 550074,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 554297,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 554570,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 603322,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 676366,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 676402,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 522075,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 522265,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 522566,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 522576,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 559056,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 603343,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 108,
        "MemberName": "TURKLAND BANK A.Ş.",
        "PrefixNo": 603005,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 108,
        "MemberName": "TURKLAND BANK A.Ş.",
        "PrefixNo": 676429,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 413729,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "ICBC TURKEY BANK A.Ş.",
        "PrefixNo": 413972,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 445988,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 456057,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 456059,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "ICBC TURKEY BANK A.Ş.",
        "PrefixNo": 514025,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 521875,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "ICBC TURKEY BANK A.Ş.",
        "PrefixNo": 527080,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 545769,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 545770,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 558634,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 109,
        "MemberName": "TEKSTİL BANKASI A.Ş.",
        "PrefixNo": 589416,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 402277,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 402278,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 402563,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 403082,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 409364,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 410147,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 413583,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 414388,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 415565,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 420092,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 422376,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 423277,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 423398,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 427311,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 435653,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 441007,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 442395,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 444029,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 459333,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 479679,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 498749,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 499850,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 499851,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 499852,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 499853,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 519324,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 521022,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 521584,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 521836,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 529572,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 530818,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 531157,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 542404,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 545120,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 545616,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 545847,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 547567,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 547800,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 677238,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 979202,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 979203,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 405903,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 405913,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 405917,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 405918,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 405919,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 408969,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 409071,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 422629,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 424909,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 428240,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 496019,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 510005,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 512651,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 519399,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 525413,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 525795,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 540643,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 542254,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 545183,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 550472,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 550473,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 552143,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 556030,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 556031,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 556033,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 556034,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 556665,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 676401,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 123,
        "MemberName": "HSBC BANK A.Ş.",
        "PrefixNo": 677193,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 466280,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 466281,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 466282,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 466283,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 466284,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 516308,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 516458,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 522221,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 544836,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 558485,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 627768,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 670670,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 414941,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 498516,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 498517,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 498518,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 498519,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 498520,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 498521,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 523759,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 548202,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 548434,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 554034,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 125,
        "MemberName": "BURGAN BANK A.Ş.",
        "PrefixNo": 558379,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 403134,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 404990,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 408625,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 409070,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 411924,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 423667,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 424360,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 424361,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 441139,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 460345,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 460346,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 460347,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 462276,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 462448,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 462449,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 465574,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 472914,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 472915,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 476662,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 483747,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 489456,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 489457,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 489458,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 508129,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 510063,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 510118,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 510119,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 512017,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 512117,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 512446,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 514924,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 515865,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 516731,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 516740,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 516914,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 517047,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 520019,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 520303,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 520909,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 522517,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 523515,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 529545,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 529876,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 533293,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 533330,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 539605,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 543358,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 543400,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 543427,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 546764,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 549938,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 552679,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 554483,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 554566,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 558443,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 558446,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 558448,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 558460,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 558514,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 670610,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 677047,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 425846,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 425847,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 425848,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 425849,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 441341,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 522240,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 522241,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 535881,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 554301,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 558593,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 603072,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 676460,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 453144,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 453145,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 453146,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 453147,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 453148,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 453149,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 504166,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 517343,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 528246,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 532813,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 534563,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 535843,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 581877,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 671121,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 671155,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 515456,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 468791,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 468793,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 468794,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 468795,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 468796,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 468797,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 469180,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 469181,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6002,
        "MemberName": "PAPARA ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş.",
        "PrefixNo": 460957,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 516944,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 519261,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 522347,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 522356,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 522362,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 524677,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 526952,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 527369,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 527383,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 527396,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 536255,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 670868,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 417715,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 534653,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 424935,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 432284,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 432285,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 511583,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 534264,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 547234,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 548232,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 627510,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 979227,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 402589,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 402590,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 402591,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 402592,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 403360,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 403810,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 410555,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 410556,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 431024,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 483714,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 511660,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 512595,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 518896,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 520180,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 525312,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 527083,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 547564,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 400742,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 404952,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 411685,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 424927,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 424931,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 428462,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 435627,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 435628,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 435629,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 441206,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 470954,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 479915,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 479916,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 479917,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 485061,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 498724,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 498725,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 511758,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 511783,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 512360,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 979217,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 521848,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 528293,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 537719,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 549294,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 552610,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 606043,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 677451,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 483602,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 535514,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 979233,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 979204,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 365770,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 365771,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 365772,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 365773,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 423478,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 483612,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 535576,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 479680,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 479681,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 534253,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 535449,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 603344,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 603650,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 979242,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 103,
        "MemberName": "FIBABANKA A.Ş.",
        "PrefixNo": 527765,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 124,
        "MemberName": "ALTERNATİF BANK A.Ş.",
        "PrefixNo": 654997,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 979216,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 537504,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 979215,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 979241,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 401072,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 423002,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 533796,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "TÜRKİYE FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 416275,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKTRONİK PARA A.Ş.",
        "PrefixNo": 979235,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 210,
        "MemberName": "VAKIF KATILIM BANKASI A.Ş.",
        "PrefixNo": 535355,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 210,
        "MemberName": "VAKIF KATILIM BANKASI A.Ş.",
        "PrefixNo": 670544,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 979244,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "FİNANS BANK A.Ş.",
        "PrefixNo": 526911,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 146,
        "MemberName": "ODEA BANK A.Ş.",
        "PrefixNo": 528064,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6002,
        "MemberName": "PAPARA ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş.",
        "PrefixNo": 531389,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6002,
        "MemberName": "PAPARA ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş.",
        "PrefixNo": 535925,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 434530,
        "CardType": "DEBIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 406281,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 657366,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 657998,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 531401,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6004,
        "MemberName": "İNİNAL ÖDEME VE ELEKTRONİK PARA HİZMETLERİ A.Ş.",
        "PrefixNo": 979266,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 549624,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 377597,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 377598,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 432952,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 432954,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 538121,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 549997,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T.VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 531369,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 434528,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 434529,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 377599,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş",
        "PrefixNo": 979246,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 203,
        "MemberName": "ALBARAKA TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 434572,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T. GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374423,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 416607,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T.VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 434724,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 15,
        "MemberName": "T.VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 522441,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 404350,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 497022,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 46,
        "MemberName": "AKBANK T.A.Ş.",
        "PrefixNo": 516643,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 374428,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 375630,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 377596,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 406665,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 406666,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 538124,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 538139,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 638888,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 685800,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 533803,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 550478,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 549839,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş",
        "PrefixNo": 534567,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş",
        "PrefixNo": 549539,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 483673,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 210,
        "MemberName": "VAKIF KATILIM BANKASI A.Ş.",
        "PrefixNo": 979265,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKTRONİK PARA A.Ş.",
        "PrefixNo": 535616,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKTRONİK PARA A.Ş.",
        "PrefixNo": 650457,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6002,
        "MemberName": "PAPARA ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş.",
        "PrefixNo": 548793,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6004,
        "MemberName": "İNİNAL ÖDEME VE ELEKTRONİK PARA HİZMETLERİ A.Ş.",
        "PrefixNo": 439748,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6004,
        "MemberName": "İNİNAL ÖDEME VE ELEKTRONİK PARA HİZMETLERİ A.Ş.",
        "PrefixNo": 478500,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6006,
        "MemberName": "PALADYUM ELEKTRONİK PARA VE ÖDEME HİZMETLERİ A.Ş.",
        "PrefixNo": 979260,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 209,
        "MemberName": "ZİRAAT KATILIM BANKASI A.Ş.",
        "PrefixNo": 535248,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 979259,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 650161,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 510054,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 476625,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 650082,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 12,
        "MemberName": "T.HALK BANKASI A.Ş.",
        "PrefixNo": 401049,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 135,
        "MemberName": "ANADOLUBANK A.Ş.",
        "PrefixNo": 979240,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKRONİK PARA A.Ş.",
        "PrefixNo": 979248,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 206,
        "MemberName": "T. FİNANS KATILIM BANKASI A.Ş.",
        "PrefixNo": 979218,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 134,
        "MemberName": "DENİZBANK A.Ş.",
        "PrefixNo": 979243,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 537829,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 432951,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 545254,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 979280,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 416563,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 650170,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T. İŞ BANKASI A.Ş.",
        "PrefixNo": 537475,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6005,
        "MemberName": "TURKCELL ÖDEME VE ELEKTRONİK PARA HİZMETLERİ A.Ş",
        "PrefixNo": 434727,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6005,
        "MemberName": "TURKCELL ÖDEME VE ELEKTRONİK PARA HİZMETLERİ A.Ş",
        "PrefixNo": 471688,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 650173,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 511885,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 537058,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 539957,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKTRONİK PARA A.Ş.",
        "PrefixNo": 478593,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKTRONİK PARA A.Ş.",
        "PrefixNo": 478594,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 111,
        "MemberName": "QNB FİNANSBANK A.Ş.",
        "PrefixNo": 559096,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 205,
        "MemberName": "KUVEYT TÜRK KATILIM BANKASI A.Ş.",
        "PrefixNo": 483674,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 533207,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 537608,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 671200,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 979249,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 979254,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6001,
        "MemberName": "TÜRK ELEKRONİK PARA A.Ş.",
        "PrefixNo": 979257,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 650456,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 460952,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI ANONİM ŞİRKETİ",
        "PrefixNo": 434913,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI ANONİM ŞİRKETİ",
        "PrefixNo": 434914,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 10,
        "MemberName": "T.C.ZİRAAT BANKASI A.Ş.",
        "PrefixNo": 482465,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 432953,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 62,
        "MemberName": "T.GARANTİ BANKASI A.Ş.",
        "PrefixNo": 538196,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 460925,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 537567,
        "CardType": "PREPAID CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 979264,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 59,
        "MemberName": "ŞEKERBANK T.A.Ş.",
        "PrefixNo": 979211,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 99,
        "MemberName": "ING BANK A.Ş.",
        "PrefixNo": 423091,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 979277,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 6003,
        "MemberName": "POSTA VE TELGRAF TEŞKİLATI A.Ş.",
        "PrefixNo": 979278,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 32,
        "MemberName": "TÜRK EKONOMİ BANKASI A.Ş.",
        "PrefixNo": 979223,
        "CardType": "CREDIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 209,
        "MemberName": "ZİRAAT KATILIM BANKASI A.Ş.",
        "PrefixNo": 670586,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 67,
        "MemberName": "YAPI VE KREDİ BANKASI A.Ş.",
        "PrefixNo": 476626,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 64,
        "MemberName": "T.İŞ BANKASI A.Ş.",
        "PrefixNo": 650987,
        "CardType": "CREDIT CARD",
        "BusinessCard": true
    },
    {
        "MemberNo": 15,
        "MemberName": "T. VAKIFLAR BANKASI T.A.O.",
        "PrefixNo": 650052,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 143,
        "MemberName": "AKTİF YATIRIM BANKASI A.Ş.",
        "PrefixNo": 979213,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    },
    {
        "MemberNo": 210,
        "MemberName": "VAKIF KATILIM BANKASI A.Ş.",
        "PrefixNo": 979250,
        "CardType": "DEBIT CARD",
        "BusinessCard": false
    }
]