import axios from "axios";
import MyHelper from "../../controller/MyHelper";

export default function DBOrder() {
  function CreateOrder(productList, addressID, userID) {
    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'create_order',
        addressID: addressID,
        userID: userID,
        productList: productList,
      })
        .then(function (response) {

          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }
  function OrderUserList() {


    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'order_user_list',
        userID: MyHelper().GetUserID(),
      })
        .then(function (response) {

          console.log(response.data)
          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }
  function OrderAdminList() {


    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'order_admin_list',
      })
        .then(function (response) {

          console.log(response.data)
          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  function OrderDetail(id) {


    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'order_user_detail',
        orderID: id
      })
        .then(function (response) {

          console.log(response.data)
          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  function OrderUpdate(id, status) {

    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'order_status_change',
        orderID: id,
        orderStatus: status,
      })
        .then(function (response) {

          console.log(response.data)
          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }

  function CargoUpdate(id, code) {

    return new Promise((ok, fail) => {
      axios.post(MyHelper().URLwithService(), {
        command: 'order_set_cargo_number',
        orderID: id,
        cargoCode: code,
      })
        .then(function (response) {

          console.log(response.data)
          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }
  return {
    CreateOrder, OrderUserList, OrderDetail, OrderAdminList, OrderUpdate, CargoUpdate
  }
}