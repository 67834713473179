import React from 'react'
import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../Ekmekk/global-data/selectors";
import { searchState, categoryState } from "../../../../Ekmekk/global-data/atom";

export const SaleContract = () => {
  const parameterList = useRecoilValue(allParametersList);

    return (
        <>
         <div className="container">
            <div className="content" style={{ marginTop: 50, marginBottom: 50 }}>
                <div className="tittle">{parameterList.filter(item => item.ek_key == "sale_tittle")[0].ek_value}</div>
                <div className="text">
                    <div dangerouslySetInnerHTML={{ __html: parameterList.filter(item => item.ek_key == "sale_description")[0].ek_value }} />
                    {/* {parameterList.filter(item => item.ek_key == "faq_description")[0].ek_value} */}
                </div>
            </div>

        </div> 
        </>
    )
}
