import { atom, atomFamily } from "recoil";
import { allParametersList } from "./selectors";

export const parameterState = atom({
    key: "parameterState",
    default: allParametersList
});


export const categoryState = atom({
    key: "categoryState",
    default: []
});

export const renderHeader = atom({
    key: "renderHeader",
    default: []
});

export const searchState = atom({
    key: "searchState",
    default: {}
});