import React from 'react'
import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../Ekmekk/global-data/selectors";
import { searchState, categoryState } from "../../../../Ekmekk/global-data/atom";

export const PrivacyPolicy = () => {
  const parameterList = useRecoilValue(allParametersList);


    return (
        <>
                 {/* <div className="pageHeader text-center" style={{ "backgroundImage": 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + parameterList.filter(item => item.ek_key == "privancyLogo")[0].ek_value + ')' }}>
            <div class="d-flex align-items-center justify-content-center " style={{ height: "100%" }}>
                {parameterList.filter(item => item.ek_key == "privancy_parent_Tittle")[0].ek_value}
            </div>
        </div> */}
        <div className="container">
            <div className="content" style={{ marginTop: 40 }}>
                <div class="wpb_wrapper">
                    <h3><strong>{parameterList.filter(item => item.ek_key == "privancy_tittle")[0].ek_value}</strong></h3>

                    <div dangerouslySetInnerHTML={{ __html: parameterList.filter(item => item.ek_key == "privancy_description")[0].ek_value }} />
                    {/* {parameterList.filter(item => item.ek_key == "privancy_description")[0].ek_value} */}

                </div>

            </div>
        </div>
        </>
    )
}
