import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../Ekmekk/global-data/selectors";
import { parameterState, categoryState, renderHeader } from "../../../../Ekmekk/global-data/atom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faShoppingBag, faHeart, faSearch, faList, faChevronCircleDown, faUser } from '@fortawesome/free-solid-svg-icons';

import BasketManager from "../../../../Ekmekk/controller/BasketManager";
import ClickListener from "../../../../Ekmekk/controller/ClickListener";
import MyHelper from "../../../../Ekmekk/controller/MyHelper";
import DataManager from "../../../../Ekmekk/database/DataManager";

import { NavLink } from 'react-router-dom';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import Select from 'react-select';

const stringOrNode = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.node,
]);
const Header = () => {
  const parameterList = useRecoilValue(allParametersList);
  const [categoryList, setCategoryList] = useState([]);
  const [render, setRender] = useRecoilState(renderHeader)

  const [isShoppingCardOpen, setIsShoppingCardOpen] = useState(false)

  const { pathname } = useLocation()
  const [path, setPath] = useState("")

  var history = useHistory();

  function MyProfile() {
    if (MyHelper().IsLogin()) {
      history.push("/profile")
    }
    else {
      history.push("/login")
    }
  }
  function OpenBasket() {
    history.push("/basket")
  }


  const [username, setUsername] = useState("")
  const [products, setProducts] = useState([])
  const [isLogin, setLogin] = useState("")
  const [basketCount, setBasketCount] = useState(0)
  const [toplamTutar, setToplamTutar] = useState(0);
  const [themeColor, setThemeColor] = useState("")

  useEffect(() => {

    setThemeColor(MyHelper().GetThemeColor(parameterList));

    DataManager().Category.GetAllCategory().then(r => {
      setCategoryList(r)
    })

    

  }, [parameterList])

 
  useEffect(() => {

    var islog = MyHelper().IsLogin();
    setLogin(islog)

    if (islog) {

      setUsername(MyHelper().GetUserName());
    }

    global.basketRefresh = Refresh;
    global.nameRefresh = RefreshName;
    document.title = parameterList.filter(item => item.ek_key == "businessSlogan")[0].ek_value;
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = parameterList.filter(item => item.ek_key == "businessLogo")[0].ek_value;
    Refresh();
    RefreshName();
    setBasketCount(BasketManager().GetCount());
    DataManager().Product.GetAllProduct().then(r => {
      setProducts([]);

      r.map((item) => {
        products.push({ label: item.ek_product_name, value: item.ek_product_name, image: item.ek_image_path, id: item.ek_product_id })
      })
      setProducts([...products]);
    });

  }, [])


  var deneme = 0;

  function Refresh() {
    setBasketCount(BasketManager().GetCount());
  }
  function GetTotalPrice() {

    var dizi = BasketManager().GetAllProductsNormal()
    var tutar = 0;
    for (let i = 0; i < dizi.length; i++) {
      if (dizi[i].days != undefined) {
        dizi[i].count = dizi[i].days
      }
      tutar = tutar + (dizi[i].count * dizi[i].price);
    }
    setToplamTutar(tutar.toFixed(2));

  }

  function RefreshName() {
    setUsername(MyHelper().GetUserName());
    setLogin(MyHelper().IsLogin());
  }

  const [baskeetItems, setBasketItems] = useRecoilState(renderHeader);
  useEffect(() => {
    setBasketItems(BasketManager().GetAllProductsNormal());

    GetTotalPrice();

  }, [])

  useEffect(() => {
    GetTotalPrice();
  }, [baskeetItems])

  useEffect(() => {
    setPath(pathname)
  }, [pathname])

  function RemoveProduct(item) {
    baskeetItems.map((mainItem, index1) => {
      if (mainItem == item) {
        var dummy = [...baskeetItems]
        dummy.splice(index1, 1);
        setBasketItems(dummy)
        BasketManager().RemoveProduct(mainItem);
        global.basketRefresh();

      }
    })
  }
  const GravatarValue = createClass({
    propTypes: {
      children: PropTypes.node,
      placeholder: stringOrNode,
      value: PropTypes.object
    },
    render() {
      return (
        <div className="Select-value" title={"asfaasg"}>
          <span className="Select-value-label">
            "asfasg"
          </span>
        </div>
      );
    }
  });
  const Option = props => {
    const { innerProps, innerRef } = props;
    return (
      <article ref={innerRef} {...innerProps} className={'custom-option'}>
        <div className="alert alert-success alert-button pl-2 pt-2">
          <a href="#" className="mr-2"><img src={props.data.image} alt="Testimonial" width={50} height={50} /></a>
          {props.data.value}
        </div>

      </article>
    );
  };
  return (
    baskeetItems && <>
      <header className="header">

        <div className="header-middle">
          <div className="container">
            <div className="header-left mr-md-4">
              <a className="mobile-menu-toggle  w-icon-hamburger">
              </a>
              <a className="logo ml-lg-0">
                <img style={{ marginLeft: "auto", marginRight: "auto" }} src={parameterList.filter(item => item.ek_key == "businessLogo")[0].ek_value} alt="logo" width={45} height={15} />
              </a>

              <form method="get" action="#" className="header-search hs-expanded hs-round d-none d-md-flex input-wrapper">

                <Select
                  onChange={(e) => { history.push("/product/" + e.id) }}
                  menuPortalTarget={document.body}
                  className="searchff"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  options={products}
                  placeholder={"Bir ürün ara"}
                  value={"sfasfa"}
                  components={{ Option }}
                  valueComponent={GravatarValue}
                />
              </form>
            </div>

            <div style={{ cursor: 'default' }} className="header-right ml-4">

              <div className="header-right ml-4">
                <div className="header-call d-xs-show d-lg-flex align-items-center">
                  <a href="tel:#" className="w-icon-call" />
                  <div className="call-info d-lg-show">
                    <h4 className="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                      <a href="mailto:#" className="text-capitalize">Hemen Ara</a> :</h4>
                    <a href="tel:#" className="phone-number font-weight-bolder ls-50">{parameterList.filter(item => item.ek_key == "businessPhone")[0].ek_value}</a>
                  </div>
                </div>

                <div className={`dropdown cart-dropdown cart-offcanvas mr-0 mr-lg-2 ${isShoppingCardOpen ? "opened" : ""}`}>
                  <div className="cart-overlay" />
                  <a onClick={() => setIsShoppingCardOpen(!isShoppingCardOpen)} style={{ cursor: 'pointer' }} className="cart-toggle label-down link">
                    <i className="w-icon-cart">
                      <span className="cart-count">{basketCount}</span>
                    </i>
                    <span className="cart-label">Sepetim</span>
                  </a>
                  <div className="dropdown-box">
                    <div className="cart-header">
                      <span>Sepetim</span>
                      <a style={{ cursor: 'pointer' }} onClick={() => setIsShoppingCardOpen(false)} className="btn-close">Kapat X</a>
                    </div>
                    <div className="products no-height" style={{ maxHeight: 9999999 }} >
                      {
                        baskeetItems == undefined ? "" : baskeetItems.map((item) => {

                          return <div className="product product-cart">
                            <div className="product-detail">
                              <a className="product-name">{item.ek_product_name}</a>
                              <div className="price-box">
                                <span className="product-quantity">{item.count}</span>
                                <span className="product-price">{item.price} ₺</span>
                              </div>
                            </div>
                            <figure className="product-media">
                              <a >
                                <img src={item.images.split("$")[0]} alt="product" width={94} height={84} />
                              </a>
                            </figure>
                            <button onClick={() => RemoveProduct(item)} className="btn btn-link btn-close">
                              <i className="fas fa-times" />
                            </button>
                          </div>
                        })
                      }


                    </div>
                    <div className="cart-total">
                      <label>Ara Toplam:</label>
                      <span className="price">{toplamTutar} ₺</span>
                    </div>
                    <div className="cart-action">
                      <a onClick={() => { history.push('/shoppingcard'); setIsShoppingCardOpen(false) }} className="btn btn-dark btn-outline btn-rounded">Karta Git</a>
                      <a onClick={() => { if (toplamTutar > 0) { history.push("/check-out"); setIsShoppingCardOpen(false) } else { MyHelper().AlertFail("Geçerli sayıda ürün giriniz") } }} className="btn btn-primary  btn-rounded">Tamamla</a>
                    </div>
                  </div>
                </div>
                {
                  MyHelper().IsLogin() ? <NavLink className="compare label-down link d-xs-show ml-lg-2 " to="/account">
                    <i className="w-icon-user" />
                    <span className="compare-label d-lg-show ">Hesabım</span>
                  </NavLink> : <NavLink to="/auth" className="compare label-down link d-xs-show ml-lg-2 ">
                    <i className="w-icon-user" />
                    <span className="compare-label d-lg-show ">Giriş Yap</span>
                  </NavLink>
                }

              </div>




            </div>
          </div>
        </div>
        {/* End of Header Middle */}
        <div className="header-bottom sticky-content fix-top sticky-header has-dropdown">
          <div className="container">
            <div className="inner-wrap">
              <div className="header-left">
                <div className={`dropdown category-dropdown ${path.length > 1 ? "" : "show-dropdown"}`} data-visible="true">
                  <a style={{ cursor: 'pointer' }} className="text-white category-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-display="static" title="Browse Categories">
                    <FontAwesomeIcon icon={faList} style={{ fontSize: 15 }} />
                    <span>Kategoriler</span>
                  </a>
                  <div className="dropdown-box">
                    <ul className="menu vertical-menu category-menu">
                      {
                        categoryList.map((item, i) => {

                          if (i < 12) {
                            return (<li>
                              <a onClick={() => { history.push('/shop/' + item.ek_id); }}>
                                <i className="w-icon-ruby" />{item.ek_category_name}
                              </a>
                            </li>
                            )
                          }

                        })
                      }


                      <li style={{ display: "none" }}>
                        <a className="font-weight-bold text-uppercase ls-25">
                          Bütün Kategoriler
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <nav className="main-nav">
                  <ul className="menu active-underline">
                    <li className={path.length <= 1 ? "active" : ""} style={{ cursor: 'pointer' }} >
                      <a onClick={() => history.push('/')} >Ana Sayfa</a>
                    </li>
                    <li className={path.length > 1 && path == '/shop' ? "active" : ""}>
                      <a onClick={() => history.push('/shop')} style={{ cursor: 'pointer' }} >Ürünler</a>
                    </li>
                    <li className={path.length > 1 && path == '/blog' ? "active" : ""}>
                      <a onClick={() => history.push('/blogs')} style={{ cursor: 'pointer' }}>Blog</a>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* <div className="header-right">
                              <a ><i className="w-icon-sale" />İndirimler</a>
                            </div> */}
            </div>
          </div>
        </div>
      </header>
      <div className="sticky-content-wrapper">
        <div className="sticky-footer sticky-content fix-bottom fixed">
          <a href="demo1.html" className="sticky-link active">
            <i className="w-icon-home" />
            <p>Home</p>
          </a>
          <a href="shop-banner-sidebar.html" className="sticky-link">
            <i className="w-icon-category" />
            <p>Shop</p>
          </a>
          <a href="my-account.html" className="sticky-link">
            <i className="w-icon-account" />
            <p>Account</p>
          </a>
          <div className="cart-dropdown dir-up">
            <a href="cart.html" className="sticky-link">
              <i className="w-icon-cart" />
              <p>Cart</p>
            </a>
            <div className="dropdown-box">
              <div className="products">
                <div className="product product-cart">
                  <div className="product-detail">
                    <h3 className="product-name">
                      <a href="product-default.html">Beige knitted elas<br />tic
                        runner shoes</a>
                    </h3>
                    <div className="price-box">
                      <span className="product-quantity">1</span>
                      <span className="product-price">$25.68</span>
                    </div>
                  </div>
                  <figure className="product-media">
                    <a href="#">
                      <img src="assets/images/cart/product-1.jpg" alt="product" width={94} height={84} />
                    </a>
                  </figure>
                  <button className="btn btn-link btn-close">
                    <i className="fas fa-times" />
                  </button>
                </div>
                <div className="product product-cart">
                  <div className="product-detail">
                    <h3 className="product-name">
                      <a href="product.html">Blue utility pina<br />fore
                        denim dress</a>
                    </h3>
                    <div className="price-box">
                      <span className="product-quantity">1</span>
                      <span className="product-price">$32.99</span>
                    </div>
                  </div>
                  <figure className="product-media">
                    <a href="#">
                      <img src="assets/images/cart/product-2.jpg" alt="product" width={84} height={94} />
                    </a>
                  </figure>
                  <button className="btn btn-link btn-close">
                    <i className="fas fa-times" />
                  </button>
                </div>
              </div>
              <div className="cart-total">
                <label>Subtotal:</label>
                <span className="price">$58.67</span>
              </div>
              <div className="cart-action">
                <a href="cart.html" className="btn btn-dark btn-outline btn-rounded">View Cart</a>
                <a href="checkout.html" className="btn btn-primary  btn-rounded">Checkout</a>
              </div>
            </div>
            {/* End of Dropdown Box */}
          </div>
          <div className="header-search hs-toggle dir-up">
            <a href="#" className="search-toggle sticky-link">
              <i className="w-icon-search" />
              <p>Search</p>
            </a>
            <form action="#" className="input-wrapper">
              <input type="text" className="form-control" name="search" autoComplete="off" placeholder="Search" required />
              <button className="btn btn-search" type="submit">
                <i className="w-icon-search" />
              </button>
            </form>
          </div>
        </div></div>

    </>
  )
}

export default Header
