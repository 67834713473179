import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MyHelper from "../../../../../Ekmekk/controller/MyHelper";
import DataManager from "../../../../../Ekmekk/database/DataManager";
import WrappedFrame from "../../Components/WrappedFrame";
import AccountBody from "./AccountBody";

export default function AddressInsert() {
    var { id } = useParams();
    var history = useHistory();
    function OnClick(item) {

        if (item.id == "btn_orders") {
            history.push("/account/orders");
        }
        else if (item.id == "btn_address") {
            history.push("/account/address");
        }
        else if (item.id == "btn_address") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_account_information") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_like") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_logout") {
            history.push("/account/ticket");
        }
        else if (item.id == "btn_address_save") {
            var inputUsernames = content.getElementsByTagName('input');
            var inputList = {};
            var isNull = false;
            for (var i = 0, l = inputUsernames.length; i < l; i++) {
                if (inputUsernames[i].value == "" || inputUsernames[i].value == null) {
                    isNull = true;
                }
                inputList[inputUsernames[i].id] = inputUsernames[i].value;

            }
            if (isNull) {
                MyHelper().AlertFail("Ops","Lütfen bütün alanları doldurduğunuzdan emin olun");
            }
            else {
                DataManager().Users.InsertAddress(inputList["ek_tittle"], inputList["ek_owner_name"], inputList["ek_phone"], inputList["ek_open_address"], inputList["ek_country"], inputList["ek_city"], inputList["ek_district"], inputList["ek_neighborhood"]).then((r)=>{
                    MyHelper().AlertSuccess();
                    history.push("/account/address");
                })

            }

        }
        else {
            alert(item.id)
        }
    }

    const [address, setAddress] = useState([]);
    const [content, setContent] = useState([]);
    useEffect(() => {



    }, [])

    function SetContent(c) {
        setContent(c);

    }

    return <>
        <WrappedFrame content={SetContent} onClick={OnClick} >
        <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL+"/thema/wolmart/assets/css/style.min.css"} />
          <AccountBody activeTab="address">

                <div className="tab-content mb-6">
                    <div className="tab-pane active in" id="account-details">
                        <div className="icon-box icon-box-side icon-box-light">
                            <span className="icon-box-icon icon-account mr-2">
                                <i className="w-icon-user" />
                            </span>
                            <div className="icon-box-content">
                                <h4 className="icon-box-title mb-0 ls-normal">Adres Ekle</h4>
                            </div>
                        </div>
                        <div className="form account-details-form" >
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="firstname">Adres Başlığı *</label>
                                        <input type="text" id="ek_tittle" name="firstname" placeholder="Adres Başlığı" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Teslimat Yapılacak Kişinin Adı *</label>
                                        <input type="text" id="ek_owner_name" name="lastname" placeholder="Teslimat Yapılacak Kişinin Adı" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Telefon</label>
                                        <input type="text" id="ek_phone" name="lastname" placeholder="Telefon" className="form-control form-control-md" />
                                    </div>
                                </div>


                            </div>

                            <h4 class="title title-password ls-25 font-weight-bold">Adres Bilgileri</h4>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Ülke *</label>
                                        <input type="text" id="ek_country" name="lastname" placeholder="Ülke" className="form-control form-control-md" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">İl *</label>
                                        <input type="text" id="ek_city" name="lastname" placeholder="İl" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">İlçe*</label>
                                        <input type="text" id="ek_district" name="lastname" placeholder="İlçe" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Mahalle *</label>
                                        <input type="text" id="ek_neighborhood" name="lastname" placeholder="Mahalle" className="form-control form-control-md" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="lastname">Açık adres</label>
                                        <input type="text" id="ek_open_address" name="lastname" placeholder="Açık adres" className="form-control form-control-md" />
                                    </div>
                                </div>
                            </div>
                            <button id="btn_address_save" type="submit" className="btn btn-dark btn-rounded btn-sm mb-4">Kaydet</button>
                        </div>
                    </div>

                </div>
            </AccountBody>


        </WrappedFrame>

    </>
}