import axios from "axios"

export default function Iyzico() {
    function Pay() {

        return new Promise((ok, fail) => {


           /* axios.get('http://www.ihtiyacvarsa.com/services/iyzico/samples/initialize_checkout_form.php?creditCardNumber=5570236505044304&cvc=610&lastMonth=01&lastYear=2024&nameSurname=Furkan Bahadır Özdemir')
                .then(response => {
                    ok(response)
                })*/
        })


    }

    return {
        Pay
    }
}