import React from 'react'

import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../../Ekmekk/global-data/selectors";
import { useHistory } from 'react-router';

const Footer = () => {
  let history = useHistory()
  const parameterList = useRecoilValue(allParametersList);

  return (
    <>
      <footer className="footer">
        <div className="container">

          <div className="footer-top">
            <div className="row">
              <div className="col-lg-5 col-sm-6">
                <div className="widget widget-about">
                  <div className="widget-body">
                    <a className="logo-footer">
                      <img style={{ objectFit: "contain" }} src={parameterList.filter(item => item.ek_key == "businessLogoFooter")[0].ek_value} alt="logo-footer" style={{ maxWidth: 145, maxHeight: 45, objectFit: "contain" }} width={145} height={45} />
                    </a>
                    <div className="header-call d-xs-show d-lg-flex align-items-center">
                      <a href="tel:#" className="w-icon-call" />
                      <div className="call-info d-lg-show">
                        <h4 className="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                          <a href="mailto:#" className="text-capitalize">Hemen Ara</a> :</h4>
                        <a href="tel:#" className="phone-number font-weight-bolder ls-50">{parameterList.filter(item => item.ek_key == "businessPhone")[0].ek_value}</a>
                      </div>
                    </div>
                    <p className="widget-about-desc">
                      {parameterList.filter(item => item.ek_key == "welcomeMessage")[0].ek_value}
                    </p>
                    <div className="social-icons social-icons-colored">
                      <a href="#" className="social-icon social-facebook w-icon-facebook" />
                      <a href="#" className="social-icon social-twitter w-icon-twitter" />
                      <a href="#" className="social-icon social-instagram w-icon-instagram" />
                      <a href="#" className="social-icon social-youtube w-icon-youtube" />
                      <a href="#" className="social-icon social-pinterest w-icon-pinterest" />
                    </div>

                    {/* <label className="label-social d-block text-dark">Social Media</label>
                                  <div className="social-icons social-icons-colored">
                                    <a   className="social-icon social-facebook w-icon-facebook" />
                                    <a   className="social-icon social-twitter w-icon-twitter" />
                                    <a   className="social-icon social-instagram w-icon-instagram" />
                                    <a   className="social-icon social-youtube w-icon-youtube" />
                                    <a   className="social-icon social-pinterest w-icon-pinterest" />
                                  </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="widget">
                  <h3 className="widget-title">Navigasyon</h3>
                  <ul className="widget-body">
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/about-us')} >Hakkımızda</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/privacy-policy')} >Gizlilik Sözleşmesi</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/shop')} >Ürünler</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/blogs')}  >Blog</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/sale-contract')}  >Mesafeli Satış Sözleşmesi</a></li>

                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">Hesabım</h4>
                  <ul className="widget-body">
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/account')} >Siparişlerim</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/account')} >Adreslerim</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/account')} >Hesap Detayı</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/account')}>İstek Listesi</a></li>
                    <li><a style={{ cursor: 'pointer' }} onClick={() => history.push('/auth')}>Giriş</a></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-left">
              <p className="copyright"> {parameterList.filter(item => item.ek_key == "businessName")[0].ek_value} © Copyright 2021 - {parameterList.filter(item => item.ek_key == "businessAffiliate")[0].ek_value} iştirakidir. Her Hakkı Saklıdır.</p>
            </div>
            <div className="footer-right">
              <span className="payment-label mr-lg-8">Güvenli Ödeme Sistemimiz</span>
              <figure className="payment">
                <img src="https://wpcozum.com/wp-content/uploads/2020/02/logo-white@2x-1-1030x356.png" alt="payment" style={{ maxWidth: 80, maxHeight: 20 }} width={159} height={25} />
              </figure>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer
